import { Radio, Select } from '@lk/lk-design-system'
import React, { FC, useCallback, useEffect, useState } from 'react'

import * as SanitySchema from '../../lib/types/sanity-schema'

export type ContentFilterFieldProps = Omit<SanitySchema.ContentFilterField, '_type'> & {
  name: string
  onFilterChange: (name: string, keyword: string) => void
}

const ContentFilterField: FC<ContentFilterFieldProps> = ({
  name,
  label: fieldLabel,
  options,
  fieldType,
  placeholder,
  onFilterChange,
}) => {
  const fieldName = `${name}`
  const [selectedKeyword, setSelectedKeyword] = useState('')
  const [selectOptions, setSelectOptions] = useState([])

  useEffect(() => {
    const fieldOptions = options.map(({ label, keyword, _key }, index) => ({
      label,
      value: keyword.keyword,
      key: `${_key}_${index}`,
    }))
    setSelectOptions(fieldOptions)
  }, [options])

  const handleFieldChange = useCallback(
    (newValue) => {
      if (selectedKeyword !== newValue) {
        setSelectedKeyword(newValue)
        onFilterChange(name, newValue)
      }
    },
    [name, onFilterChange, selectedKeyword],
  )

  useEffect(() => {
    onFilterChange(name, selectedKeyword)
  }, [name, onFilterChange, selectedKeyword])

  return (
    <div className="lk-content-filter__field">
      {fieldType === 'radio' && (
        <>
          <div className="lk-content-filter__field__label">{fieldLabel}</div>
          {options.map(({ label, keyword, _key }) => (
            <Radio
              name={fieldName}
              key={_key}
              label={label}
              onChange={() => handleFieldChange(keyword?.keyword)}
              checked={keyword?.keyword === selectedKeyword}
            />
          ))}
        </>
      )}
      {fieldType === 'select' && (
        <Select
          name={fieldName}
          options={selectOptions}
          placeholder={placeholder}
          onSelect={(e) => {
            const { value: targetValue } = e.target
            const newValue = (Array.isArray(targetValue) ? targetValue[0] : targetValue) || ''
            handleFieldChange(newValue)
          }}
          label={fieldLabel}
        />
      )}
    </div>
  )
}

export default ContentFilterField
