import { Button, Input } from '@lk/lk-design-system'
import React, { FC, useCallback, useContext, useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useFieldValidation from '../../../../../hooks/useFieldValidation'
import LKApis from '../../../../../services/lk-apis/LKApisProvider'
import { PatchTravelProposalArgs, TravelOffer } from '../../../../../services/lk-apis/types'
import { DocWithSlug } from '../../../../CTALink/CTALink'
import LegalCheckbox from '../../../../LegalCheckbox/LegalCheckbox'

interface FinalFormProps {
  travelOffer: TravelOffer
  legalLink: DocWithSlug
  onSubmit: (values: PatchTravelProposalArgs) => void
}

const initialFormValues: PatchTravelProposalArgs = { selectedTravelOffer: '', fullName: '', email: '', mobile: '' }

const TravelInsuranceFinalForm: FC<FinalFormProps> = ({ travelOffer, legalLink, onSubmit }) => {
  const [data, updateData] = useReducer(
    (prev: PatchTravelProposalArgs, newData: Partial<PatchTravelProposalArgs>) => ({ ...prev, ...newData }),
    initialFormValues,
  )
  const [errors, updateErrors] = useReducer(
    (prev: Record<string, string>, newData: Partial<Record<string, string>>) => ({ ...prev, ...newData }),
    {},
  )
  const [legalAccepted, setLegalAccepted] = useState(false)
  const [offerAccepted, setOfferAccepted] = useState(false)
  const { t } = useTranslation()
  const { lkWebApi } = useContext(LKApis)
  const { isValidEmail, isValidMobile, isValidFullName } = useFieldValidation()

  useEffect(() => {
    updateData({ ...initialFormValues, selectedTravelOffer: travelOffer?.id })
  }, [travelOffer.id])

  const validateForm = useCallback(() => {
    const newErrors = {
      fullName: !isValidFullName(data.fullName) ? t('widgetTravelInsurance.errors.nameError') : undefined,
      email: !isValidEmail(data.email) ? t('widgetTravelInsurance.errors.emailError') : undefined,
      mobile: !isValidMobile(data.mobile) ? t('widgetTravelInsurance.errors.phoneError') : undefined,
    }
    updateErrors(newErrors)
    return Object.entries(newErrors).filter(([, value]) => value !== undefined).length === 0
  }, [data.email, data.fullName, data.mobile, isValidEmail, isValidFullName, isValidMobile, t])

  const handleSubmit = useCallback(() => {
    if (validateForm() && legalAccepted && offerAccepted) {
      onSubmit(data)
    }
  }, [validateForm, legalAccepted, offerAccepted, onSubmit, data])

  return (
    <div className="lk-widget-travel__form">
      <div className="row">
        <div className="col-12">
          <Input
            type="text"
            label={t('widgetTravelInsurance.name')}
            placeholder={t('widgetTravelInsurance.namePlaceholder')}
            onChange={(e) => updateData({ fullName: e.target.value })}
            error={!!errors?.fullName}
            errorMessage={errors?.fullName}
            defaultValue={data.fullName}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Input
            type="email"
            label={t('widgetTravelInsurance.email')}
            placeholder={t('widgetTravelInsurance.emailPlaceholder')}
            onChange={(e) => updateData({ email: e.target.value })}
            error={!!errors?.email}
            errorMessage={errors?.email}
            defaultValue={data.email}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Input
            type="tel"
            label={t('widgetTravelInsurance.phone')}
            placeholder={t('widgetTravelInsurance.phonePlaceholder')}
            onChange={(e) => updateData({ mobile: e.target.value })}
            error={!!errors?.mobile}
            errorMessage={errors?.mobile}
            defaultValue={data.mobile}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <LegalCheckbox
            fieldName="legal"
            legalTitle={t('widgetTravelInsurance.offerCheck')}
            legalLink={lkWebApi.getTravelProposalDocUrl(travelOffer.id)}
            onLegalChange={(name, accepted) => setOfferAccepted(accepted)}
            docType="pdf"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <LegalCheckbox
            fieldName="legal"
            legalTitle={t('widgetTravelInsurance.legalCheck')}
            legalLink={`/${legalLink?.slug?.current}`}
            onLegalChange={(name, accepted) => setLegalAccepted(accepted)}
          />
        </div>
      </div>

      <div className="lk-widget-travel__actions">
        <Button
          buttonType="button"
          onClick={() => handleSubmit()}
          label={t('widgetTravelInsurance.hireButton')}
          disabled={!legalAccepted || !offerAccepted}
        />
      </div>
    </div>
  )
}

export default TravelInsuranceFinalForm
