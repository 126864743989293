import './PromoCard.scss'

import { BrandedIcon, Tag } from '@lk/lk-design-system'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as SanitySchema from '../../../../lib/types/sanity-schema'
import PortableText from '../../../PortableText/PortableText'

export interface PromoCardProps {
  promo: SanitySchema.Promo
}

const PromoCard: React.FC<PromoCardProps> = ({ promo }) => {
  const { t } = useTranslation()
  const [activeClass, setActiveClass] = useState<'active' | 'inactive'>('inactive')
  const { title, body, relatedDocs, scheduledStartDate, scheduledEndDate } = promo

  useEffect(() => {
    const startDate = Date.parse(scheduledStartDate)
    const endDate = Date.parse(scheduledEndDate)
    const now = Date.now()
    setActiveClass(now >= startDate && now <= endDate ? 'active' : 'inactive')
  }, [scheduledEndDate, scheduledStartDate])

  return (
    <article className={classNames('promo-card', activeClass)}>
      <div className="promo-card__title">
        <Tag aspect="status" status={activeClass} text={t(`widgetPromosList.${activeClass}`)} />
        <h3 className="lk-shortcut__title lk-shortcut__title--upper">{title}</h3>
      </div>
      <div className="promo-card__body">
        <PortableText blocks={body} />
        <ul className="promo-card__docs">
          {relatedDocs.map(({ pdf, title: docTitle }) => (
            <li key={pdf._id}>
              <a href={`/${pdf.slug?.current}`}>{docTitle}</a>
            </li>
          ))}
        </ul>
      </div>
      <div className="promo-card__footer">
        <BrandedIcon name="calendar" size={20} />
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t('widgetPromosList.dates', { from: scheduledStartDate, to: scheduledEndDate }),
          }}
        />
      </div>
    </article>
  )
}

export default PromoCard
