import './TableWithStyle.scss'

import React, { FC } from 'react'

import * as SanitySchema from '../../lib/types/sanity-schema'

export type TableWithStyleProps = Omit<SanitySchema.SanityKeyed<SanitySchema.TableWithStyle>, '_type'>

const TableWithStyle: FC<TableWithStyleProps> = ({ _key, title, table, headers, cellAlign }) => {
  const withRowHeader = headers?.includes('withRowHeader') || false
  const tableHeader = withRowHeader ? table.rows.slice(0, 1) : []
  const tableData = withRowHeader ? table.rows.slice(1) : table.rows
  const cellStyle = {
    textAlign: cellAlign || 'center',
  }

  return (
    <table className="lk-table table-with-style">
      <caption>{title}</caption>
      <thead>
        {tableHeader?.map((row) => (
          <tr key={row._key}>
            {row.cells.map((cell, thIdx) => {
              const key = `${tableHeader._key}#${thIdx}`
              return (
                <th scope="col" key={key} style={cellStyle}>
                  {cell}
                </th>
              )
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {tableData?.map((row) => (
          <tr key={row._key}>
            {row.cells?.map((cell, cellIdx) => {
              const isHeader = cellIdx === 0 && headers?.includes('withColHeader')
              const key = `${row._key}#${cellIdx}`
              return isHeader ? (
                <th scope="row" key={key} style={cellStyle}>
                  {cell}
                </th>
              ) : (
                <td key={key} style={cellStyle}>
                  {cell}
                </td>
              )
            })}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default TableWithStyle
export const TableWithStyleBuilder = ({ title, table, cellAlign, headers, _key }: TableWithStyleProps) => (
  <TableWithStyle title={title} table={table} cellAlign={cellAlign} headers={headers} _key={_key} />
)
