import { Table } from '@lk/lk-design-system'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import LKApis from '../../../../services/lk-apis/LKApisProvider'

const WidgetCurrencyExchangeTable: FC = () => {
  const { t } = useTranslation()
  const { lkWebApi } = useContext(LKApis)
  const { language } = useContext(I18nextContext)
  const [currencies, setCurrencies] = useState([])
  const [pending, setPending] = React.useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await lkWebApi.getCurrencyExchanges()
        const newCurrencies = data.data.currencyExchanges.map((currency) => ({
          currencyId: currency.currencyId,
          label: language === 'eu' ? currency.nameEU : currency.name,
          billPurchaseValue: currency.billPurchaseValue,
          billSaleValue: currency.billSaleValue,
          purchaseValue: currency.purchaseValue,
          saleValue: currency.saleValue,
        }))
        setCurrencies(newCurrencies)
      } catch (e) {
        console.log(e)
      }
      setPending(false)
    }
    fetchData()
  }, [language, lkWebApi])

  return (
    <>
      <div data-testid="currencyExchangeTableBills">
        <Table
          columns={[
            {
              name: t('widgetExchange.referenceLabel'),
              selector: (c) => c.label,
              grow: 3,
              hide: 'sm',
            },
            {
              name: '',
              selector: (c) => c.currencyId,
            },
            {
              name: t('widgetExchange.purchaseLabel'),
              selector: (c) => (c.billPurchaseValue ? c.billPurchaseValue : '-'),
              right: true,
            },
            {
              name: t('widgetExchange.saleLabel'),
              selector: (c) => (c.billSaleValue ? c.billSaleValue : '-'),
              right: true,
            },
          ]}
          title={t('widgetExchange.titleBills')}
          subHeader
          subHeaderComponent={<span className="text-small">{t('widgetExchange.subtitleBills')}</span>}
          data={currencies}
          sortServer={false}
          pagination={false}
          striped
          selectableRows={false}
          progressPending={pending}
          noDataComponent={t('widgetExchange.genericError')}
        />
      </div>
      <br />
      <div data-testid="currencyExchangeTableCurrency">
        <Table
          columns={[
            {
              name: t('widgetExchange.referenceLabel'),
              selector: (c) => c.label,
              grow: 3,
              hide: 'sm',
            },
            {
              name: '',
              selector: (c) => c.currencyId,
            },
            {
              name: t('widgetExchange.purchaseLabel'),
              selector: (c) => (c.purchaseValue > 0 ? c.purchaseValue : '-'),
              right: true,
            },
            {
              name: t('widgetExchange.saleLabel'),
              selector: (c) => (c.saleValue > 0 ? c.saleValue : '-'),
              right: true,
            },
          ]}
          title={t('widgetExchange.titleCurrency')}
          subHeader
          subHeaderComponent={<span className="text-small">{t('widgetExchange.subtitleCurrency')}</span>}
          data={currencies}
          sortServer={false}
          pagination={false}
          striped
          selectableRows={false}
          progressPending={pending}
          noDataComponent={t('widgetExchange.genericError')}
        />
      </div>
    </>
  )
}

export default WidgetCurrencyExchangeTable
