import './Comparative.scss'

import React, { FC } from 'react'

import SanitySchema from '../../lib/types/sanity-schema'
import CTALink from '../CTALink/CTALink'
import Figure from '../Figure/Figure'
import PortableText from '../PortableText/PortableText'

export type ComparativeProps = Omit<SanitySchema.Comparative, '_type'> & { id: string }

const Comparative: FC<ComparativeProps> = ({ id, title, labels, items }) => (
  <table className="comparative">
    <caption className="comparative-title">{title}</caption>
    <thead>
      <tr className="comparative-row__header">
        <th aria-hidden className="comparative__first-column" />
        {items.map(({ name, _key, image }) => (
          <th key={_key} className="comparative-cell">
            {image && <Figure node={image} />}
            {name}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {labels.map((label, indexLabel) => {
        const key = `${id}-label-${label}`
        return (
          <tr key={key} className="comparative-row__data">
            <th scope="row" className="comparative__first-column comparative-cell">
              {label}
            </th>
            {items.map(({ values }, indexItem) => {
              const itemValue = values.find((value) => value.key === label)
              const itemKey = `${key}-item-${indexLabel}-${indexItem}`
              return (
                <td key={itemKey} className="comparative-cell">
                  <PortableText blocks={itemValue?.value || []} />
                </td>
              )
            })}
          </tr>
        )
      })}
      <tr className="comparative-row__data">
        <th aria-hidden className="comparative__first-column" />
        {items.map(({ moreInfo, _key }) => (
          <td key={_key} className="comparative-cell more-information">
            {moreInfo?.title && (
              <CTALink
                title={moreInfo?.title}
                route={moreInfo?.route}
                link={moreInfo?.link}
                pageRoute={moreInfo?.pageRoute}
                kind={moreInfo?.kind}
              />
            )}
          </td>
        ))}
      </tr>
    </tbody>
  </table>
)

export default Comparative
