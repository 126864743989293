const useFieldValidation = () => {
  const hasContent = (value: string) => value !== ''

  const isValidFullName = (fullName: string) => {
    const fullNameRegex = /^[a-záéíóúñ]+( [a-záéíóúñ]+)+$/i
    return fullNameRegex.test(fullName)
  }

  const isValidEmail = (email: string) => {
    const emailRegex = /\S+@\S+\.\S+/
    return emailRegex.test(email)
  }

  const isValidMobile = (mobile: string) => {
    const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{9}$/
    return mobileRegex.test(mobile)
  }

  return {
    hasContent,
    isValidEmail,
    isValidMobile,
    isValidFullName,
  }
}

export default useFieldValidation
