import './Information.scss'

import { Icon } from '@lk/lk-design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'

const confortOptionImages = {
  bicycle: '/Components/WidgetInsuranceCalculator/bici.png',
  motorcycle: '/Components/WidgetInsuranceCalculator/moto.png',
}

const confortAdvantages = ['firstAdvantage', 'secondAdvantage', 'thirdAdvantage', 'fourthAdvantage']
const confortOptions = ['bicycle', 'motorcycle']

const ConfortInformation = () => {
  const { t } = useTranslation()

  return (
    <div className="lk-calculator__aside col-lg-4 col-md-12 bg-berenjena-claro-4">
      <div className="advantages-box advantages-box__confort">
        <p
          className="text-large"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t(`widgetInsuranceCalculator.advantagesConfort.title`),
          }}
        />
        {confortAdvantages.map((advantage) => (
          <div key={`confort_${advantage}`} className="advantages-box__confort-cobertures">
            <Icon name="check" />
            <div>{t(`widgetInsuranceCalculator.advantagesConfort.${advantage}`)}</div>
          </div>
        ))}
        <p className="confort__text-upper text-large strong">
          {t(`widgetInsuranceCalculator.advantagesConfort.includes`)}
        </p>
        {confortOptions.map((advantage) => (
          <div key={`confort_${advantage}`} className="confort">
            <img className="confort__icon" src={confortOptionImages[advantage]} alt="" />
            <div className="confort__text">
              <p className="text-large">{t(`widgetInsuranceCalculator.advantagesConfort.${advantage}`)}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ConfortInformation
