import { pdfjs } from 'react-pdf'

// must be mocked in tests
// see ../../../__test__/config/jest-setup.ts

export const setupPdfWorker = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString()
}

export default {}
