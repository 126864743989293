import './Card.scss'

import { Card as CardDS } from '@lk/lk-design-system'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import React, { FC } from 'react'

import { getSanityClientLkConfig } from '../../lib/lkWebConfig'
import * as SanitySchema from '../../lib/types/sanity-schema'
import PortableText from '../PortableText/PortableText'

export type CardProps = Omit<SanitySchema.Card, '_type'>

const Card: FC<CardProps> = ({ title, image, body, cardType, imageSize }) => {
  let imageData
  let imageAlt

  if (image) {
    imageAlt = image?.alt
    imageData = getGatsbyImageData(image, { width: 675 }, getSanityClientLkConfig())
  }

  const extraClass = imageSize ? `lk-card__${imageSize}` : undefined

  return (
    <CardDS
      type={cardType}
      title={title}
      image={imageData?.images?.fallback?.src}
      imageAlt={imageAlt}
      body={<PortableText blocks={body} />}
      className={extraClass}
    />
  )
}

export default Card
export const CardBuilder = ({ cardType, title, image, body, keywords, imageSize }: CardProps) => (
  <Card cardType={cardType} title={title} image={image} body={body} keywords={keywords} imageSize={imageSize} />
)
