import React, { FC } from 'react'

const COOKIEBOT_DECLARATION_WRAPPER_ID = 'cookiebot-declaration-wrapper'

export interface CookieBotProps {
  language: string
}

const WidgetCookieBotDetails: FC<CookieBotProps> = ({ language }) => {
  React.useEffect(() => {
    // Inject Cookiebot declaration
    const scriptTag = document.createElement('script')
    scriptTag.setAttribute('src', `https://consent.cookiebot.com/${process.env.GATSBY_COOKIEBOT_ID}/cd.js`)
    scriptTag.setAttribute('type', 'text/javascript')
    scriptTag.setAttribute('async', 'async')
    scriptTag.setAttribute('data-culture', language)

    const cookiebotWrapperEl = document.getElementById(COOKIEBOT_DECLARATION_WRAPPER_ID)
    if (cookiebotWrapperEl) {
      cookiebotWrapperEl.appendChild(scriptTag)
    }
  }, [language])
  return <div id={COOKIEBOT_DECLARATION_WRAPPER_ID} data-testid="lk-widget-cookiebot-details" />
}

export default WidgetCookieBotDetails
