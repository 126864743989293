export const getCaptchaToken = async (captchaRef) => {
  try {
    const token = await captchaRef.current.executeAsync()
    if (!token) {
      return 'EMPTY_TOKEN_ERROR'
    }
    return token
  } catch (e) {
    console.warn('CustomForm.getCaptchaToken', e)
    return 'GET_TOKEN_ERROR'
  }
}

export default () => getCaptchaToken
