import './BannerImageAside.scss'

import React, { ReactElement } from 'react'

import { BannerImageProps } from '../../types'

export const bannerImageAside: BannerImageProps = {
  sm: {
    width: 345,
    height: 115,
  },
  md: {
    width: 320,
    height: 564,
  },
  lg: {
    width: 675,
    height: 598,
  },
}

export interface SlideFigureWithTextProps {
  title: string
  body: ReactElement
  footer: ReactElement
  cta: ReactElement<HTMLButtonElement>
  img: ReactElement<HTMLImageElement>
  placeImageLeft: boolean
}

const BannerImageRight: React.FC<SlideFigureWithTextProps> = ({ title, body, footer, img, cta, placeImageLeft }) => (
  <div className={`bannerImageAside ${placeImageLeft ? 'bannerImageAsideLeft' : ''}`}>
    {img && <figure className="slide-image">{img}</figure>}
    <div className="slide-text">
      <div className="slide-text__content">
        {title && <p className="lk-hero__heading">{title}</p>}
        {body && <div className="lk-hero__body">{body}</div>}
      </div>
      {cta && <div className="lk-hero__actions">{cta}</div>}
      {footer && <div className="lk-hero__footer">{footer}</div>}
    </div>
  </div>
)

export default BannerImageRight
