import './BannerVideo.scss'

import classNames from 'classnames'
import React, { ReactElement } from 'react'
import ReactPlayer from 'react-player'

export interface BannerVideoProps {
  title: string
  body: ReactElement
  footer: ReactElement
  cta: ReactElement<HTMLButtonElement>
  videoUrl: string
  onVideoEnded?: () => void
}

const BannerVideo: React.FC<BannerVideoProps> = ({ title, body, footer, cta, videoUrl, onVideoEnded }) => (
  <div className="bannerVideo">
    <figure className={classNames('slide-video')}>
      <ReactPlayer
        url={videoUrl}
        muted
        playing
        controls
        onEnded={onVideoEnded}
        width="100%"
        height="100%"
        aria-hidden="true"
        loop={false}
      />
    </figure>
    <span className="slide-description">
      {title && <p className="lk-hero__heading">{title}</p>}
      {body && <div className="lk-hero__body">{body}</div>}
      {cta && <div className="lk-hero__actions">{cta}</div>}
      {footer && <div className="lk-hero__footer">{footer}</div>}
    </span>
  </div>
)

BannerVideo.defaultProps = {
  onVideoEnded: () => null,
}

export default BannerVideo
