import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import LKApis from '../../../../../services/lk-apis/LKApisProvider'
import { PostBolRegistrationCustomerArgs } from '../../../../../services/lk-apis/types'
import AccessibleModal from '../../../../AccessibleModal/AccessibleModal'
import BolApiErrorMessage from '../../../common/BolApiErrorMessage'
import BaseForm, { FormFields } from '../common/BaseForm'
import { BolFormProps, BolStep } from '../common/types'

const formFields: FormFields[] = ['name', 'surname', 'email', 'mobile', 'city', 'address', 'postalCode']

export interface BolRegisterWithoutCCFormProps extends BolFormProps {
  petitionId: string
}

const BolRegisterWithoutCCForm: React.FC<BolRegisterWithoutCCFormProps> = ({ identifier, petitionId, onSuccess }) => {
  const { lkWebApi } = useContext(LKApis)
  const { t } = useTranslation()
  const [success, setSuccess] = useState(false)
  const [errorCode, setErrorCode] = useState<string>(null)

  useEffect(() => {
    setSuccess(false)
  }, [identifier])

  const handleSubmit = useCallback(
    async (data) => {
      const apiData: PostBolRegistrationCustomerArgs = { ...data, petitionId }
      try {
        const response = await lkWebApi.customerManagement.postBolRegistrationCustomer(apiData)
        if (response?.status === 201) {
          setSuccess(true)
        } else {
          setSuccess(false)
          setErrorCode('UNEXPECTED_STATUS_CODE')
        }
      } catch (error) {
        setErrorCode(error?.response?.data?.errors?.detail || 'UNKNOWN_ERROR')
      }
    },
    [lkWebApi],
  )

  return (
    <div data-testid="bol-form-register-without-cc">
      <p className="lk-bol-new-user__title">{t('widgetBolNewUser.registerWithoutCcIntro')}</p>
      <BaseForm
        identifier={identifier}
        onFormSubmit={handleSubmit}
        fields={formFields}
        submitLabel={t('widgetBolNewUser.registerWithoutCcButton')}
        showLegalCheckbox
      />

      <BolApiErrorMessage errorCode={errorCode} onClose={() => setErrorCode(null)} />

      <AccessibleModal
        active={success}
        heading={t('widgetBolNewUser.registerWithoutCcOk.title')}
        fixed
        onClose={() => {
          setSuccess(false)
          onSuccess(BolStep.IdForm)
        }}
      >
        <div>{t('widgetBolNewUser.registerWithoutCcOk.message')}</div>
      </AccessibleModal>
    </div>
  )
}

export default BolRegisterWithoutCCForm
