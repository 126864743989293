import React, { FC, useEffect } from 'react'

import { getWeecoverLkConfig } from '../../../../lib/lkWebConfig'

export interface WidgetWeecoverProps {
  language: string
}

const SCRIPT_ID = 'weecover-script'
const WRAPPER_ID = 'lk-widget-weecover'

const WidgetWeecover: FC<WidgetWeecoverProps> = ({ language }) => {
  const { url, environment, collective, agentId, subAgentId } = getWeecoverLkConfig()

  useEffect(() => {
    if (!url || url === 'CONFIG_NOT_SET') {
      console.warn('Weecover widget not configured')
      return
    }
    const wrapperEl = document.getElementById(WRAPPER_ID)
    if (wrapperEl && !document.getElementById(SCRIPT_ID)) {
      const scriptTag = document.createElement('script')
      scriptTag.setAttribute('src', url)
      scriptTag.setAttribute('type', 'text/javascript')
      scriptTag.setAttribute('id', SCRIPT_ID)
      wrapperEl.appendChild(scriptTag)
    }
    document.body.addEventListener('widget:loaded', () => {
      const { render } = window.weecoverWidget.default.widget(WRAPPER_ID)
      render(language.toUpperCase(), environment, collective, agentId, subAgentId)
    })
  }, [agentId, collective, environment, language, subAgentId, url])

  return <div id={WRAPPER_ID} data-testid="lk-widget-weecover" />
}

export default WidgetWeecover
