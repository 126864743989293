import './Information.scss'

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

const initialAdvantages = ['firstAdvantage', 'secondAdvantage', 'thirdAdvantage', 'fourthAdvantage']

const InitialInformation = () => {
  const { t } = useTranslation()

  return (
    <div className="lk-calculator__aside  col-lg-4 col-md-12 bg-berenjena-claro-4">
      <div className="advantages-box">
        <p className="advantages-box__title text-large strong">
          {t(`widgetInsuranceCalculator.advantagesInitialForm.title`)}
        </p>
        <div className="advantages-box__content">
          {initialAdvantages.map((advantage) => (
            <div key={`initial_${advantage}`} className="advantages-box__content-item">
              <span className="advantages-box__content-item__icon">
                <span aria-hidden="true" role="img" className="lk-icon lki-check" />
              </span>
              <div>
                <Trans
                  className="text-large"
                  i18nKey={`widgetInsuranceCalculator.advantagesInitialForm.${advantage}`}
                  components={{ b: <b /> }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default InitialInformation
