import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const FinishLessThan14: FC = () => {
  const { t } = useTranslation()
  return (
    <div className="lk-bol-new-user" data-testid="bol-less-than-14">
      <p>{t('widgetBolNewUser.thanksForYourInterest')}</p>
      <p>{t('widgetBolNewUser.finishLessThan14')}</p>
    </div>
  )
}

export default FinishLessThan14
