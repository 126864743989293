import './Chat.scss'

import React, { FC } from 'react'

import { getChatLkConfig } from '../../lib/lkWebConfig'

const WRAPPER_ID = 'livebeep-script'

// Original LiveBeep script
//
// <!--Start of LiveBeep Script-->
//   <script type="text/javascript">
//   (function(d,s,id){
//     if(d.getElementById(id)){return;}
//     var u='//www.livebeep.com/eye.js?duk=CODE';
//     if((h=d.location.href.split(/#ev!/)[1])) u += '?_e=' +h;
//     else if((r=/.*\_evV=(\w+)\b.*/).test(c=d.cookie) ) u += '?_v='+c.replace(r,'$1');
//     var js = d.createElement(s);
//     js.src = u;js.id = id;
//     var fjs = d.getElementsByTagName(s)[0];
//     fjs.parentNode.insertBefore(js, fjs);
//   })(document,'script','livebeep-script');
//   </script>
// <!--End of LiveBeep Script-->
export interface ChatProps {
  chatType: string
}

const chatUrlForType = (chatType: string): string => {
  const config = getChatLkConfig()
  let chatUrl = config[chatType]
  if (!chatUrl) {
    return ''
  }
  const h = document.location.href.split(/#ev!/)[1]
  if (h) {
    chatUrl += `?_e=${h}`
  } else {
    const c = document.cookie
    const r = /.*_evV=(\w+)\b.*/
    if (r.test(c)) {
      chatUrl += `?_v=${c.replace(r, '$1')}`
    }
  }
  return chatUrl
}

const Chat: FC<ChatProps> = ({ chatType }) => {
  React.useEffect(() => {
    const url = chatUrlForType(chatType)
    if (url) {
      const scriptTag = document.createElement('script')
      scriptTag.setAttribute('src', url)
      scriptTag.setAttribute('type', 'text/javascript')
      const wrapper = document.getElementById(WRAPPER_ID)
      wrapper?.appendChild(scriptTag)
    }
  }, [chatType])
  return <div id={WRAPPER_ID} data-testid="chat-wrapper" className="chat-button" />
}

export default Chat
