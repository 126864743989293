import { graphql, useStaticQuery } from 'gatsby'

import { Cta } from '../lib/types/sanity-schema'

const useCtaUtils = () => {
  const { pages, pdfs } = useStaticQuery(graphql`
    query AllDocUrls {
      pages: allSanityPage {
        edges {
          node {
            _id
            slug {
              current
            }
          }
        }
      }
      pdfs: allSanityPdf {
        edges {
          node {
            _id
            slug {
              current
            }
          }
        }
      }
    }
  `)

  const absoluteUrl = (slug: string): string => {
    if (slug.startsWith('http://') || slug.startsWith('https://') || slug.startsWith('/')) {
      return slug
    }
    return `/${slug}`
  }

  const resolveCtaReferences = (cta: Cta): Cta => {
    if (!cta.pageRoute || cta.pageRoute.slug?.current) {
      return cta
    }
    const doc =
      pages?.edges?.find((c) => c.node?._id === cta.pageRoute?._ref) ||
      pdfs?.edges?.find((c) => c.node?._id === cta.pageRoute?._ref)
    if (doc) {
      const result = { ...cta, route: absoluteUrl(doc.node.slug.current) }
      delete result.pageRoute
      return result
    }
    return cta
  }

  return {
    absoluteUrl,
    resolveCtaReferences,
  }
}

export default useCtaUtils
