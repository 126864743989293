import { Checkbox } from '@lk/lk-design-system'
import React, { FC } from 'react'

interface InsuranceCalculatorOfferBoxProps {
  children: React.ReactElement | React.ReactElement[]
  selected: boolean
  onClick: () => void
}

const InsuranceCalculatorOfferBox: FC<InsuranceCalculatorOfferBoxProps> = ({ children, selected, onClick }) => (
  <div
    className={`lk-calculator__content lk-widget-insurance-calculator__offer bg-berenjena-claro-4 ${
      selected ? 'selected' : ''
    }`}
    role="button"
    tabIndex={0}
    aria-pressed={selected}
    onClick={onClick}
    onKeyDown={(event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault()
        onClick()
      }
    }}
  >
    {children}
    <Checkbox
      className="lk-widget-insurance-calculator__checkbox"
      checked={selected}
      aria-hidden="true"
      label="offer-checkbox"
      tabIndex={-1}
      onClick={(event) => {
        event.preventDefault()
      }}
    />
  </div>
)

export default InsuranceCalculatorOfferBox
