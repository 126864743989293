import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import HelpBox from '../common/HelpBox'

export interface FinishBlockedByOfficeProps {
  language: string
}

const FinishBlockedByOffice: FC<FinishBlockedByOfficeProps> = ({ language }) => {
  const { t } = useTranslation()
  const helpLinks = {
    offices: { es: '/es/oficinas-cajeros/', eu: '/eu/bulegoak-kutxazainak/', ca: '/ca/oficines-caixes/' },
  }
  return (
    <div className="lk-bol-new-user" data-testid="bol-finish-blocked-by-office">
      <p>{t('widgetBolNewUser.finishBlockedByOffice.intro')}</p>
      <div className="lk-bol-new-user__help-boxes col-lg-6">
        <HelpBox
          title={t('widgetBolNewUser.finishBlockedByOffice.offices.title')}
          body={t('widgetBolNewUser.finishBlockedByOffice.offices.text')}
          buttonText={t('widgetBolNewUser.finishBlockedByOffice.offices.button')}
          href={`${helpLinks.offices[language]}?tab=offices`}
        />
      </div>
    </div>
  )
}

export default FinishBlockedByOffice
