import { Button } from '@lk/lk-design-system'
import React, { FC } from 'react'

export interface HelpBoxProps {
  title: string
  body: string
  buttonText?: string
  href?: string
  target?: string
  onClick?: () => void
}

const HelpBox: FC<HelpBoxProps> = ({ title, body, buttonText, href, target, onClick }) => (
  <div className="lk-bol-new-user__helpBox">
    <div className="lk-bol-new-user__helpBox-title">{title}</div>
    <div
      className="lk-bol-new-user__helpBox-text"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: body }}
    />
    <div className="lk-bol-new-user__helpBox-button">
      {href && (
        <a href={href} target={target} className="lk-cta-link lk-button lk-button-secondary lk-button--sm">
          {buttonText}
        </a>
      )}
      {onClick && (
        <Button
          onClick={onClick}
          label={buttonText}
          className="lk-cta-link lk-button lk-button-secondary lk-button--sm"
        />
      )}
    </div>
  </div>
)

HelpBox.defaultProps = {
  href: '',
  target: '_self',
  buttonText: '',
  onClick: null,
}

export default HelpBox
