import { Button, Input } from '@lk/lk-design-system'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { defaultName } from '../cards_config'
import { CardsViewerProps, LkCard } from '../types'
import CustomCard from './CustomCard'
import RequestCardButtons from './RequestCardButtons'

const CardsSlider: FC<CardsViewerProps> = ({ cards }) => {
  const [name, setName] = useState('')
  const [selectedCard, setSelectedCard] = useState<LkCard>(null)
  const [previousCard, setPreviousCard] = useState<LkCard>(null)
  const [nextCard, setNextCard] = useState<LkCard>(null)
  const { t } = useTranslation()

  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => setSelectedIndex(0), [cards])

  useEffect(() => {
    const maxIndex = cards.length - 1
    setPreviousCard(selectedIndex > 0 ? cards[selectedIndex - 1] : null)
    setSelectedCard(cards[selectedIndex])
    setNextCard(selectedIndex < maxIndex ? cards[selectedIndex + 1] : null)
  }, [cards, selectedIndex])

  return (
    <div data-testid="cards-slider">
      <div className="row">
        <div className="col-xs-12 col-lg-12 col-sm-12">
          <Input
            onChange={(e) => setName(e.target.value)}
            placeholder={t('widgetCustomizeYourCard.namePlaceholder')}
            label={t('widgetCustomizeYourCard.nameLabel')}
          />
        </div>
      </div>
      <div className="lk-custom-card__cards card-container">
        {selectedCard?.id && (
          <CustomCard
            id={selectedCard.id}
            image={selectedCard.image}
            label={selectedCard.label}
            name={name || defaultName}
            className="selected"
            onClick={() => {}}
            key="card-slider-selected"
          />
        )}
      </div>
      <div className="lk-custom-card__buttons">
        <Button
          className="lk-carousel__slide-button"
          buttonType="compact"
          icon="chevron-left-slim"
          disabled={!previousCard?.id}
          onClick={() => setSelectedIndex(selectedIndex - 1)}
          aria-label={t('widgetCustomizeYourCard.prevCard')}
        />
        <Button
          className="lk-carousel__slide-button"
          buttonType="compact"
          icon="chevron-right-slim"
          disabled={!nextCard?.image}
          aria-label={t('widgetCustomizeYourCard.nextCard')}
          onClick={() => setSelectedIndex(selectedIndex + 1)}
        />
      </div>
      <RequestCardButtons selectedCard={selectedCard} />
    </div>
  )
}

export default CardsSlider
