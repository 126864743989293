import './WidgetBlogFeed.scss'

import { Shortcut } from '@lk/lk-design-system'
import React, { FC, useEffect, useState } from 'react'

import useI18nUtils from '../../../../hooks/useI18nUtils'
import SanitySchema from '../../../../lib/types/sanity-schema'
import useBlogFeedCache, { BlogPost } from './useBlogFeedCache'

export interface WidgetBlogFeedProps {
  feed: SanitySchema.BlogFeed & { id: string }
}

const BlogPostCard: FC<{ post: BlogPost }> = ({ post }) => {
  const { formatDate } = useI18nUtils()
  const { link, title, pubDate, categories } = post
  const tag = categories ? categories[0] : ''

  return <Shortcut type="blog" tag={tag} title={title} cta={link} date={formatDate(pubDate)} />
}

const WidgetBlogFeed: FC<WidgetBlogFeedProps> = ({ feed }) => {
  const { id, numberOfPosts, categoryFilter } = feed
  const { blog, loadBlogById } = useBlogFeedCache()
  const [posts, setPosts] = useState<BlogPost[]>([])

  useEffect(() => {
    if (loadBlogById) {
      loadBlogById(id)
    }
  }, [id, loadBlogById])

  useEffect(() => {
    const newPosts = (blog?.items || [])
      .filter((post) =>
        categoryFilter
          ? (post?.categories || []).map((c) => c.toLowerCase()).includes(categoryFilter.toLocaleLowerCase())
          : true,
      )
      .slice(0, numberOfPosts)
    setPosts(newPosts)
  }, [blog, categoryFilter, numberOfPosts])

  return (
    <div className="widgetBlogFeed" data-testid="lk-widget-blog-feed">
      {posts.map((post) => (
        <BlogPostCard post={post} key={post.guid} />
      ))}
    </div>
  )
}

export default WidgetBlogFeed
