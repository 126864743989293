import { I18nextContext } from 'gatsby-plugin-react-i18next'
import React, { FC, useContext } from 'react'

import { cardsImagesPath } from '../cards_config'
import { LkCard } from '../types'

export interface CustomCardProps extends LkCard {
  name: string
  className: string
}

const CustomCard: FC<CustomCardProps> = ({ image, name, className, onClick, label }) => {
  const { language } = useContext(I18nextContext)
  return (
    <div
      className={`lk-custom-card__card ${className}`}
      role="button"
      aria-label={label[language] || ''}
      onKeyUp={(e) => e.key === 'Enter' && onClick()}
      onClick={onClick}
      tabIndex={0}
    >
      <p className="lk-custom-card__card__name">{name}</p>
      <img src={`${cardsImagesPath}/${image}`} alt={label[language] || ''} className="lk-custom-card__card__back" />
      <img
        src="/Components/WidgetCustomizeYourCard/card-mask.png"
        alt=""
        aria-hidden="true"
        className="lk-custom-card__card__top"
      />
    </div>
  )
}

export default CustomCard
