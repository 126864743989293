import { Input } from '@lk/lk-design-system'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AccessibleModal from '../../../../AccessibleModal/AccessibleModal'
import { defaultName } from '../cards_config'
import { CardsViewerProps, LkCard } from '../types'
import CustomCard from './CustomCard'
import RequestCardButtons from './RequestCardButtons'

const CardsGrid: FC<CardsViewerProps> = ({ cards, name }) => {
  const [selectedCard, setSelectedCard] = useState<LkCard>(null)
  const [clientName, setClientName] = useState('')
  const { t } = useTranslation()

  return (
    <div className="lk-custom-card__cards-grid" data-testid="cards-grid">
      {cards.map((card) => (
        <CustomCard
          key={card.id}
          id={card.id}
          image={card.image}
          label={card.label}
          name={clientName || defaultName}
          onClick={() => setSelectedCard(card)}
          className="lk-custom-card__cards-grid-item col-lg-3"
        />
      ))}

      <AccessibleModal
        heading=""
        active={selectedCard !== null}
        onClose={() => {
          setSelectedCard(null)
        }}
        size="large"
        fixed
      >
        <div data-testid="cardModal">
          <div className="row">
            <div className="col-lg-12">
              <h2
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: t('widgetCustomizeYourCard.title') }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Input
                onChange={(e) => setClientName(e.target.value)}
                placeholder={t('widgetCustomizeYourCard.namePlaceholder')}
                label={t('widgetCustomizeYourCard.nameLabel')}
              />
            </div>
          </div>
          <div className="row card-container">
            {selectedCard && (
              <CustomCard
                key={selectedCard.id}
                id={selectedCard.id}
                image={selectedCard.image}
                label={selectedCard.label}
                name={clientName || defaultName}
                onClick={() => setSelectedCard(selectedCard)}
                className="col-xs-6 as-center"
              />
            )}
          </div>
          <div className="row">
            <RequestCardButtons selectedCard={selectedCard} />
          </div>
        </div>
      </AccessibleModal>
    </div>
  )
}

export default CardsGrid
