import React, { FC, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import LKApis from '../../../../../services/lk-apis/LKApisProvider'
import BolApiErrorMessage from '../../../common/BolApiErrorMessage'
import LkCaptcha, { LkCaptchaRef } from '../../../common/LkCaptcha'
import WidgetBolBaseForm from '../common/BaseForm'
import { BolErrorDetail, BolFormCallbackProps, BolStep } from '../common/types'

const IdForm: FC<BolFormCallbackProps> = ({ onSuccess }) => {
  const captchaRef = React.useRef<LkCaptchaRef>()
  const { lkWebApi } = useContext(LKApis)
  const { t } = useTranslation()
  const [errorCode, setErrorCode] = useState<string>(null)

  const handleSubmit = useCallback(
    async (data) => {
      const { identifier } = data
      try {
        const captchaToken = await captchaRef.current.getCaptchaToken()
        const response = await lkWebApi.customerManagement.postPin({ identifier }, captchaToken)
        const responseBody = response?.data?.data
        if (response?.status === 201) {
          onSuccess(BolStep.OtpForm, { identifier, petitionId: responseBody.petitionId })
        } else {
          setErrorCode('UNEXPECTED_STATUS_CODE')
        }
      } catch (error) {
        const errorDetail = error?.response?.data?.errors?.detail
        setErrorCode(errorDetail || 'UNKNOWN_ERROR')
        if (errorDetail === BolErrorDetail.BusinessIdNotSupported) {
          onSuccess(BolStep.FinishBusinessIdNotSupported)
        }
      }
    },
    [lkWebApi, onSuccess],
  )

  return (
    <div data-testid="bol-form-id">
      <p className="lk-bol-new-user__title">{t('widgetBolNewUser.subtitle.id')}</p>
      <WidgetBolBaseForm
        fields={['identifier']}
        identifier=""
        showLegalCheckbox
        onFormSubmit={handleSubmit}
        submitLabel={t('widgetBolNewUser.identifierContinueButton')}
      />
      <LkCaptcha ref={captchaRef} />
      <BolApiErrorMessage errorCode={errorCode} onClose={() => setErrorCode(null)} />
    </div>
  )
}

export default IdForm
