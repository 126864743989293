import './WidgetTalentclueCareers.scss'

import React, { FC, useEffect } from 'react'

export interface WidgetTalentclueCareersProps {
  language: string
}

const WRAPPER_ID = 'lk-widget-talentclue-careers'
const SCRIPT_ID = 'tc-jswidget-script'

const WidgetTalentclueCareers: FC<WidgetTalentclueCareersProps> = ({ language }) => {
  useEffect(() => {
    const wrapperElement = document.getElementById(WRAPPER_ID)
    if (wrapperElement && !document.getElementById(SCRIPT_ID)) {
      const scriptTag = document.createElement('script')
      scriptTag.setAttribute('src', `https://careers.talentclue.com/sites/static/widget/jswidget.min.js`)
      scriptTag.setAttribute('type', 'text/javascript')
      scriptTag.setAttribute('id', SCRIPT_ID)
      wrapperElement.appendChild(scriptTag)
    }
  }, [language])
  return (
    <div id={WRAPPER_ID} data-testid="lk-widget-talentclue-careers">
      <div id="tc-jswidget" data-lang={language} data-job-listing="1" data-client-id="da99da1b28f77908d1a8e5c28786519b">
        talentclue
      </div>
    </div>
  )
}

export default WidgetTalentclueCareers
