import { LkCardCategory } from './types'

export const defaultName = 'JOSE ANDRES FERNANDEZ BENGOETXEA'
export const cardsImagesPath = '/Components/WidgetCustomizeYourCard/cards'

export const LaboralKutxaCards: LkCardCategory[] = [
  {
    id: 'ani',
    label: {
      es: 'Animales',
      eu: 'Animaliak',
    },
    cards: [
      { id: 'ani001', image: 'ani001.jpg', label: { es: 'perro', eu: 'txakur' } },
      { id: 'ani002', image: 'ani002.jpg', label: { es: 'estrella de mar', eu: 'itsas izar' } },
      { id: 'ani004', image: 'ani004.jpg', label: { es: 'guepardo', eu: 'gepardo' } },
      { id: 'ani005', image: 'ani005.jpg', label: { es: 'petirrojo', eu: 'txantxangorri' } },
      { id: 'ani007', image: 'ani007.jpg', label: { es: 'mariquitas', eu: 'marigorringo' } },
      { id: 'ani008', image: 'ani008.jpg', label: { es: 'oso polar', eu: 'hartz polarra' } },
      { id: 'ani011', image: 'ani011.jpg', label: { es: 'vaca', eu: 'behi' } },
      { id: 'ani013', image: 'ani013.jpg', label: { es: 'gato en silla', eu: 'aulki katua' } },
      { id: 'ani014', image: 'ani014.jpg', label: { es: 'perro en  tabla de paddle', eu: 'txakurra paddle taulan' } },
      { id: 'ani015', image: 'ani015.jpg', label: { es: 'perro con pelota', eu: 'txakurra pilotarekin' } },
      { id: 'ani017', image: 'ani017.jpg', label: { es: 'pez payaso', eu: 'pailazo arraina' } },
      { id: 'ani018', image: 'ani018.jpg', label: { es: 'caballos debajo de árbol', eu: 'zaldiak zuhaitz azpian' } },
      { id: 'ani019', image: 'ani019.jpg', label: { es: 'jirafas', eu: 'jirafak' } },
      { id: 'ani020', image: 'ani020.jpg', label: { es: 'cebra', eu: 'zebra' } },
      { id: 'ani021', image: 'ani021.jpg', label: { es: 'gato en silla', eu: 'katua aulki batean' } },
      { id: 'ani022', image: 'ani022.jpg', label: { es: 'águila', eu: 'arrano' } },
      { id: 'ani023', image: 'ani023.jpg', label: { es: 'cigüeña', eu: 'zikoina' } },
      { id: 'ani024', image: 'ani024.jpg', label: { es: 'perro en la montaña', eu: 'mendiko txakurra' } },
      { id: 'ani025', image: 'ani025.jpg', label: { es: 'delfín', eu: 'izurde' } },
      { id: 'ani026', image: 'ani026.jpg', label: { es: 'caballo', eu: 'zaldia' } },
      { id: 'ani027', image: 'ani027.jpg', label: { es: 'león', eu: 'lehoi' } },
      { id: 'ani028', image: 'ani028.jpg', label: { es: 'pingüinos', eu: 'pingüino' } },
      { id: 'ani029', image: 'ani029.jpg', label: { es: 'medusa', eu: 'medusa' } },
      { id: 'ani030', image: 'ani030.jpg', label: { es: 'rana', eu: 'iguela' } },
      { id: 'ani031', image: 'ani031.jpg', label: { es: 'tiburón', eu: 'marrazo' } },
      { id: 'ani032', image: 'ani032.jpg', label: { es: 'pantera', eu: 'pantera' } },
      { id: 'ani033', image: 'ani033.jpg', label: { es: 'caballo', eu: 'zaldia' } },
      { id: 'ani034', image: 'ani034.jpg', label: { es: 'papagayo', eu: 'papagai' } },
      { id: 'ani035', image: 'ani035.jpg', label: { es: 'tigre', eu: 'tigrea' } },
      { id: 'ani036', image: 'ani036.jpg', label: { es: 'iguana', eu: 'iguana' } },
      { id: 'ani037', image: 'ani037.jpg', label: { es: 'flamenco', eu: 'flamenko' } },
    ],
  },
  {
    id: 'concep',
    label: {
      es: 'Conceptos',
      eu: 'Kontzeptuak',
    },
    cards: [
      { id: 'concep002', image: 'concep002.jpg', label: { es: 'carretera', eu: 'errepide' } },
      { id: 'concep003', image: 'concep003.jpg', label: { es: 'plátano', eu: 'banana' } },
      { id: 'concep004', image: 'concep004.jpg', label: { es: 'cometa barco', eu: 'kometa itsasontzia' } },
      { id: 'concep006', image: 'concep006.jpg', label: { es: 'carretera difuminada', eu: 'errepide lausotua' } },
      {
        id: 'concep007',
        image: 'concep007.jpg',
        label: { es: 'abstracto mezcla de colores', eu: 'laburpena koloreen nahasketa' },
      },
      { id: 'concep008', image: 'concep008.jpg', label: { es: 'zapatillas', eu: 'oinezkoak' } },
      { id: 'concep009', image: 'concep009.jpg', label: { es: 'notas musicales', eu: 'ohar musikalak' } },
      {
        id: 'concep011',
        image: 'concep011.jpg',
        label: { es: 'amanecer en el planeta tierra', eu: 'egunsentia lurrean' },
      },
      {
        id: 'concep012',
        image: 'concep012.jpg',
        label: { es: 'jardin zen camino de piedras', eu: 'zen lorategia piedras bidea' },
      },
      { id: 'concep013', image: 'concep013.jpg', label: { es: 'gota de agua', eu: 'uraren jaitsiera' } },
      { id: 'concep014', image: 'concep014.jpg', label: { es: 'fresas', eu: 'marrubiak' } },
      { id: 'concep015', image: 'concep015.jpg', label: { es: 'espiral', eu: 'kiribil' } },
      { id: 'concep018', image: 'concep018.jpg', label: { es: 'serpentina', eu: 'serpentina' } },
      { id: 'concep023', image: 'concep023.jpg', label: { es: 'reflejos agua', eu: 'uraren erreflexuak' } },
      { id: 'concep024', image: 'concep024.jpg', label: { es: 'patron abstracto gris', eu: 'eredu abstraktu grisa' } },
      { id: 'concep025', image: 'concep025.jpg', label: { es: 'globo aerostático', eu: 'aire beroa globoa' } },
      { id: 'concep027', image: 'concep027.jpg', label: { es: 'espirales', eu: 'kiribilak' } },
      { id: 'concep028', image: 'concep028.jpg', label: { es: 'hombre con casco', eu: 'kaskoa duen gizona' } },
      { id: 'concep029', image: 'concep029.jpg', label: { es: 'vaca corriendo', eu: 'behia korrika' } },
      { id: 'concep030', image: 'concep030.jpg', label: { es: 'pelota y cintas', eu: 'pilota eta zinta' } },
      { id: 'concep031', image: 'concep031.jpg', label: { es: 'dibujos abstracto', eu: 'marrazketa abstraktua' } },
      { id: 'concep032', image: 'concep032.jpg', label: { es: 'dibujos abstracto', eu: 'marrazketa abstraktua' } },
      { id: 'concep033', image: 'concep033.jpg', label: { es: 'dibujos abstracto', eu: 'marrazketa abstraktua' } },
      { id: 'concep034', image: 'concep034.jpg', label: { es: 'pelota en escalera', eu: 'pilota eskaileretan' } },
      { id: 'concep035', image: 'concep035.jpg', label: { es: 'espiral', eu: 'espirala' } },
      {
        id: 'concep036',
        image: 'concep036.jpg',
        label: { es: 'árbol dentro de círculo', eu: 'zuhaitza zirkulu barruan' },
      },
    ],
  },
  {
    id: 'cultura',
    label: {
      es: 'Cultura',
      eu: 'Kultura',
    },
    cards: [
      { id: 'cultura001', image: 'cultura001.jpg', label: { es: 'bailarina ballet', eu: 'ballet dancer' } },
      { id: 'cultura002', image: 'cultura002.jpg', label: { es: 'pirámides de egipto', eu: 'egiptoko piramideak' } },
      {
        id: 'cultura003',
        image: 'cultura003.jpg',
        label: { es: 'mimo delante torre eiffel', eu: 'mimo torre eiffel aurrean' },
      },
      { id: 'cultura005', image: 'cultura005.jpg', label: { es: 'redes de pesca', eu: 'arrantza sareak' } },
      { id: 'cultura007', image: 'cultura007.jpg', label: { es: 'arbol rojo', eu: 'zuhaitz gorria' } },
      { id: 'cultura008', image: 'cultura008.jpg', label: { es: 'billete 100 pesetas', eu: '100 pezetako txartela' } },
      { id: 'cultura009', image: 'cultura009.jpg', label: { es: 'pintura ojos', eu: 'begiak margotzen' } },
      {
        id: 'cultura010',
        image: 'cultura010.jpg',
        label: { es: 'pintura abstracta cuadros', eu: 'pintura margolan abstraktuak' },
      },
      { id: 'cultura011', image: 'cultura011.jpg', label: { es: 'paleta de pintor', eu: 'paleta' } },
      { id: 'cultura013', image: 'cultura013.jpg', label: { es: 'tren a vapor', eu: 'lurrun trena' } },
      { id: 'cultura014', image: 'cultura014.jpg', label: { es: 'torre Eiffel', eu: 'Eiffel dorrea' } },
      { id: 'cultura016', image: 'cultura016.jpg', label: { es: 'San Fermines', eu: 'San Ferminak' } },
      { id: 'cultura017', image: 'cultura017.jpg', label: { es: 'Celedón', eu: 'eledón' } },
      { id: 'cultura019', image: 'cultura019.jpg', label: { es: 'marijaia', eu: 'marijaia' } },
      {
        id: 'cultura020',
        image: 'cultura020.jpg',
        label: { es: 'bailarina tradicional desde arriba', eu: 'goiko dantzari tradizionala' },
      },
      { id: 'cultura021', image: 'cultura021.jpg', label: { es: 'ikurriña desenfocada', eu: 'ikurriña desagertuta' } },
      {
        id: 'cultura023',
        image: 'cultura023.jpg',
        label: { es: 'hombres tocando la txalaparta', eu: 'gizona txalaparta jotzen' },
      },
      {
        id: 'cultura024',
        image: 'cultura024.jpg',
        label: { es: 'imagen degradada de personas', eu: 'pertsonen irudi degradatua' },
      },
      {
        id: 'cultura025',
        image: 'cultura025.jpg',
        label: { es: 'dibujo de perfil de dos hombres', eu: 'bi gizonen perfilezko marrazkia' },
      },
      { id: 'cultura026', image: 'cultura026.jpg', label: { es: 'notas musicales', eu: 'musika-notak' } },
      { id: 'cultura027', image: 'cultura027.jpg', label: { es: 'escultura de hombre', eu: 'gizonen eskultura' } },
      { id: 'cultura028', image: 'cultura028.jpg', label: { es: 'sombra bailarina', eu: 'itzal dantzaria' } },
      { id: 'cultura029', image: 'cultura029.jpg', label: { es: 'puntos en color', eu: 'koloretako puntuak' } },
      { id: 'cultura030', image: 'cultura030.jpg', label: { es: 'bola discoteca', eu: 'diskoteka bola' } },
      {
        id: 'cultura031',
        image: 'cultura031.jpg',
        label: { es: 'persona levantando guitarra', eu: 'pertsona gitarra altxatzen' },
      },
      {
        id: 'cultura032',
        image: 'cultura032.jpg',
        label: { es: 'dibujo persona en canoa', eu: 'pertsona baten marrazkia kanoan' },
      },
      { id: 'cultura033', image: 'cultura033.jpg', label: { es: 'carlina', eu: 'eguzkilore' } },
    ],
  },
  {
    id: 'deporte',
    label: {
      es: 'Deporte',
      eu: 'Kirola',
    },
    cards: [
      { id: 'deporte001', image: 'deporte001.jpg', label: { es: 'escater', eu: 'eskater' } },
      { id: 'deporte002', image: 'deporte002.jpg', label: { es: 'motocross', eu: 'trialera' } },
      { id: 'deporte003', image: 'deporte003.jpg', label: { es: 'baloncesto', eu: 'saskibaloi' } },
      { id: 'deporte004', image: 'deporte004.jpg', label: { es: 'formula 1', eu: '1. formula' } },
      { id: 'deporte006', image: 'deporte006.jpg', label: { es: 'ski', eu: 'eskiatu' } },
      { id: 'deporte007', image: 'deporte007.jpg', label: { es: 'surf', eu: 'zer egin' } },
      { id: 'deporte008', image: 'deporte008.jpg', label: { es: 'snow board', eu: 'elur mahai' } },
      { id: 'deporte009', image: 'deporte009.jpg', label: { es: 'barco a vela', eu: 'untzi' } },
      { id: 'deporte010', image: 'deporte010.jpg', label: { es: 'mountain bike', eu: 'mendiko bizikleta' } },
      { id: 'deporte011', image: 'deporte011.jpg', label: { es: 'montañero', eu: 'aliamendune' } },
      { id: 'deporte014', image: 'deporte014.jpg', label: { es: 'pelota antigua', eu: 'antzinako pilota' } },
      { id: 'deporte015', image: 'deporte015.jpg', label: { es: 'remeros', eu: 'arraunlari' } },
      { id: 'deporte016', image: 'deporte016.jpg', label: { es: 'aizcolari', eu: 'aizkora' } },
      { id: 'deporte018', image: 'deporte018.jpg', label: { es: 'ciclista', eu: 'txirrindulari' } },
      { id: 'deporte019', image: 'deporte019.jpg', label: { es: 'pala de pelota', eu: 'wakeboarding' } },
      {
        id: 'deporte022',
        image: 'deporte022.jpg',
        label: { es: 'corte de troncos con sierra', eu: 'sierra enborrak' },
      },
      { id: 'deporte023', image: 'deporte023.jpg', label: { es: 'surfistas con bici', eu: 'bizikleta surflariak' } },
      { id: 'deporte028', image: 'deporte028.jpg', label: { es: 'zapatilla de desporte', eu: 'kirol oinetakoa' } },
      { id: 'deporte029', image: 'deporte029.jpg', label: { es: 'sombras en cumbre', eu: 'itzalak gailurrean' } },
      { id: 'deporte030', image: 'deporte030.jpg', label: { es: 'surfistas', eu: 'surfistak' } },
      { id: 'deporte031', image: 'deporte031.jpg', label: { es: 'canoa en el mar', eu: 'canoa itsasoan' } },
      {
        id: 'deporte032',
        image: 'deporte032.jpg',
        label: { es: 'persona subida a una roca', eu: 'arroka batera igotako pertsona' },
      },
      {
        id: 'deporte033',
        image: 'deporte033.jpg',
        label: { es: 'persona sugentando bicicleta', eu: 'pertsona bizikletari heltzen' },
      },
      { id: 'deporte034', image: 'deporte034.jpg', label: { es: 'parapente', eu: 'parapentea' } },
      {
        id: 'deporte035',
        image: 'deporte035.jpg',
        label: { es: 'ciclistas con tabla de surf', eu: 'surf taula duen txirrindularia' },
      },
      {
        id: 'deporte036',
        image: 'deporte036.jpg',
        label: { es: 'barco rojo entre hielo', eu: 'ontzi gorria izotz artean' },
      },
      { id: 'deporte037', image: 'deporte037.jpg', label: { es: 'paddle surf', eu: 'paddle surfa' } },
      { id: 'deporte038', image: 'deporte038.jpg', label: { es: 'ciclista', eu: 'txirrindularia' } },
      {
        id: 'deporte039',
        image: 'deporte039.jpg',
        label: { es: 'sombras de una carrera', eu: 'lasterketa baten itzalak' },
      },
      { id: 'deporte040', image: 'deporte040.jpg', label: { es: 'portero en acción', eu: 'atezaina martxan' } },
      { id: 'deporte041', image: 'deporte041.jpg', label: { es: 'futbolista en acción', eu: 'futbolaria ekinean' } },
      { id: 'deporte042', image: 'deporte042.jpg', label: { es: 'futbolista en acción', eu: 'futbolaria ekinean' } },
      {
        id: 'deporte043',
        image: 'deporte043.jpg',
        label: { es: 'ciclistas laboral kutxa', eu: 'laboral kutxako txirrindularia' },
      },
      {
        id: 'deporte044',
        image: 'deporte044.jpg',
        label: { es: 'ciclistas laboral kutxa', eu: 'laboral kutxako txirrindularia' },
      },
    ],
  },
  {
    id: 'natu',
    label: {
      es: 'Naturaleza',
      eu: 'Natura',
    },
    cards: [
      { id: 'natu001', image: 'natu001.jpg', label: { es: 'flor diente de león', eu: 'león hortz lorea' } },
      { id: 'natu003', image: 'natu003.jpg', label: { es: 'margaritas', eu: 'margaritas' } },
      { id: 'natu011', image: 'natu011.jpg', label: { es: 'rosa', eu: 'arrosa' } },
      { id: 'natu016', image: 'natu016.jpg', label: { es: 'tulipanes morados', eu: 'tulipak moreak' } },
      { id: 'natu020', image: 'natu020.jpg', label: { es: 'girasoles', eu: 'udazkeneko orria' } },
      { id: 'natu027', image: 'natu027.jpg', label: { es: 'texturas', eu: 'testurak ' } },
      { id: 'natu028', image: 'natu028.jpg', label: { es: 'pajaros en el mar', eu: 'txoriak itsasoan ' } },
      { id: 'natu029', image: 'natu029.jpg', label: { es: 'rocas de costa', eu: 'kostaldeko harriak ' } },
      { id: 'natu030', image: 'natu030.jpg', label: { es: 'salto entre rocas', eu: 'arroken arteko jauzia' } },
      { id: 'natu031', image: 'natu031.jpg', label: { es: 'niño en la costa', eu: 'haurra kostaldean ' } },
      { id: 'paisaje001', image: 'paisaje001.jpg', label: { es: 'sol en el bosque', eu: 'eguzkia basoan' } },
      { id: 'paisaje002', image: 'paisaje002.jpg', label: { es: 'arboles nevados', eu: 'elurrak zuhaitzak' } },
      { id: 'paisaje003', image: 'paisaje003.jpg', label: { es: 'faro entre olas', eu: 'beacon olatuen artean' } },
      { id: 'paisaje004', image: 'paisaje004.jpg', label: { es: 'playa tropical', eu: 'hondartza tropikala' } },
      { id: 'paisaje005', image: 'paisaje005.jpg', label: { es: 'cascada', eu: 'ur-jauzi' } },
      { id: 'paisaje006', image: 'paisaje006.jpg', label: { es: 'muelle', eu: 'kai' } },
      { id: 'paisaje007', image: 'paisaje007.jpg', label: { es: 'montañas', eu: 'mooutains' } },
      { id: 'paisaje008', image: 'paisaje008.jpg', label: { es: 'pico montaña', eu: 'mendi garaia' } },
      { id: 'paisaje009', image: 'paisaje009.jpg', label: { es: 'paisaje de montaña', eu: 'mendiko paisaia' } },
      { id: 'paisaje010', image: 'paisaje010.jpg', label: { es: 'paisaje de costa', eu: 'costa paisaia' } },
      { id: 'paisaje011', image: 'paisaje011.jpg', label: { es: 'montañera', eu: 'mendizalea ' } },
      { id: 'paisaje012', image: 'paisaje012.jpg', label: { es: 'banderas tibet', eu: 'tibet banderak' } },
      { id: 'paisaje013', image: 'paisaje013.jpg', label: { es: 'arroyo', eu: 'erreka' } },
      { id: 'paisaje014', image: 'paisaje014.jpg', label: { es: 'bosque', eu: 'oihan' } },
      { id: 'paisaje016', image: 'paisaje016.jpg', label: { es: 'costa 1', eu: 'kosta 1' } },
      { id: 'paisaje017', image: 'paisaje017.jpg', label: { es: 'costa 2', eu: 'costa 2' } },
      { id: 'paisaje020', image: 'paisaje020.jpg', label: { es: 'montaña', eu: 'mendi' } },
      { id: 'paisaje022', image: 'paisaje022.jpg', label: { es: 'caserio', eu: 'baitan' } },
      { id: 'paisaje023', image: 'paisaje023.jpg', label: { es: 'peine de los vientos', eu: 'haitzarako' } },
      { id: 'paisaje024', image: 'paisaje024.jpg', label: { es: 'faro', eu: 'itsasargi' } },
    ],
  },
  {
    id: 'urbanas',
    label: {
      es: 'Urbanas',
      eu: 'Hirigunea',
    },
    cards: [
      { id: 'urbanas001', image: 'urbanas001.jpg', label: { es: 'londres', eu: 'londres' } },
      { id: 'urbanas002', image: 'urbanas002.jpg', label: { es: 'new york', eu: 'new york' } },
      {
        id: 'urbanas003',
        image: 'urbanas003.jpg',
        label: { es: 'ciudad de rascacielos', eu: 'etxe orratzen hiria' },
      },
      { id: 'urbanas005', image: 'urbanas005.jpg', label: { es: 'bicicletas', eu: 'bizikleta' } },
      { id: 'urbanas007', image: 'urbanas007.jpg', label: { es: 'vespa', eu: 'vespa' } },
      { id: 'urbanas011', image: 'urbanas011.jpg', label: { es: 'puente', eu: 'zubia' } },
      { id: 'urbanas012', image: 'urbanas012.jpg', label: { es: 'surfista en la ciudad', eu: 'surflaria hirian' } },
      { id: 'urbanas013', image: 'urbanas013.jpg', label: { es: 'calle urbana', eu: 'hiri kalea' } },
      { id: 'urbanas015', image: 'urbanas015.jpg', label: { es: 'paisaje urbano', eu: 'hiri paisaia' } },
      { id: 'urbanas016', image: 'urbanas016.jpg', label: { es: 'barcos', eu: 'itsasontziak' } },
      { id: 'urbanas017', image: 'urbanas017.jpg', label: { es: 'farola', eu: 'kale argia' } },
      {
        id: 'urbanas019',
        image: 'urbanas019.jpg',
        label: { es: 'puppy el perro de Guggenheim', eu: 'txakurkume guggenge-ren txakurra' },
      },
      { id: 'urbanas020', image: 'urbanas020.jpg', label: { es: 'Guggenheim', eu: 'Guggenheim' } },
      {
        id: 'urbanas021',
        image: 'urbanas021.jpg',
        label: { es: 'puente colgante de portugalete', eu: 'portugalete esekidura zubia' },
      },
      { id: 'urbanas022', image: 'urbanas022.jpg', label: { es: 'estructuras de hierro', eu: 'burdinaren egiturak' } },
      { id: 'urbanas023', image: 'urbanas023.jpg', label: { es: 'escaleras del metro', eu: 'metroko eskailerak' } },
      { id: 'urbanas024', image: 'urbanas024.jpg', label: { es: 'eskater', eu: 'eskaterra' } },
      { id: 'urbanas025', image: 'urbanas025.jpg', label: { es: 'puente', eu: 'zubia' } },
      { id: 'urbanas026', image: 'urbanas026.jpg', label: { es: 'casas', eu: 'etxeak' } },
      { id: 'urbanas027', image: 'urbanas027.jpg', label: { es: 'pasarela', eu: 'pasabide' } },
      { id: 'urbanas028', image: 'urbanas028.jpg', label: { es: 'motorista', eu: 'motor gidaria' } },
      { id: 'urbanas029', image: 'urbanas029.jpg', label: { es: 'plaza de pamplona', eu: 'iruinako plaza' } },
      { id: 'urbanas030', image: 'urbanas030.jpg', label: { es: 'Guggenheim', eu: 'Guggenheim' } },
      { id: 'urbanas031', image: 'urbanas031.jpg', label: { es: 'coche antiguo', eu: 'auto zaharra' } },
      { id: 'urbanas032', image: 'urbanas032.jpg', label: { es: 'vista de la ria', eu: 'itsasadarraren ikuspegia' } },
      { id: 'urbanas033', image: 'urbanas033.jpg', label: { es: 'puente sobre el mar', eu: 'itsas gaineko zubia' } },
    ],
  },
  {
    id: 'joven',
    label: {
      es: 'Joven',
      eu: 'Gazteak',
    },
    cards: [
      {
        id: 'joven001',
        image: 'joven001.jpg',
        label: { es: 'Dibujo chica con paraguas', eu: 'Neskaren marrazketa aterkiarekin' },
      },
      { id: 'joven002', image: 'joven002.jpg', label: { es: 'ilustraciones en verde', eu: 'Ilustrazio berdeak' } },
      { id: 'joven003', image: 'joven003.jpg', label: { es: 'ilustraciones en color', eu: 'koloretako irudiak' } },
      { id: 'joven004', image: 'joven004.jpg', label: { es: 'ilustraciones en color', eu: 'koloretako irudiak' } },
      {
        id: 'joven005',
        image: 'joven005.jpg',
        label: { es: 'ilustraciones en blanco y negro', eu: 'Zuri-beltzeko irudiak' },
      },
      { id: 'joven006', image: 'joven006.jpg', label: { es: 'ilustraciones en color', eu: 'koloretako irudiak' } },
      { id: 'joven007', image: 'joven007.jpg', label: { es: 'ilustraciones en color', eu: 'koloretako irudiak' } },
      { id: 'joven008', image: 'joven008.jpg', label: { es: 'Astronauta', eu: 'Astronauta' } },
      { id: 'joven009', image: 'joven009.jpg', label: { es: 'Ilustracción de torso', eu: 'Gorputz baten marrazkia' } },
      { id: 'joven010', image: 'joven010.jpg', label: { es: 'Ciudad', eu: 'Hiria' } },
      { id: 'joven011', image: 'joven011.jpg', label: { es: 'ilustraciones en negro', eu: 'Zuri-beltzeko irudiak' } },
      {
        id: 'joven012',
        image: 'joven012.jpg',
        label: { es: 'Calavera con gato encima', eu: 'Garezurra, katu bat gainean duela' },
      },
      {
        id: 'joven013',
        image: 'joven013.jpg',
        label: { es: 'Un cuerpo con flores en lugar de cabeza', eu: 'Gorputz bat loreekin buruaren ordez' },
      },
      { id: 'joven014', image: 'joven014.jpg', label: { es: 'Flores', eu: 'Lorak' } },
      { id: 'joven015', image: 'joven015.jpg', label: { es: 'calaveras', eu: 'Garezurrak' } },
      { id: 'joven016', image: 'joven016.jpg', label: { es: 'Ilustración skater', eu: 'Eskater baten marrazkia' } },
      { id: 'joven017', image: 'joven017.jpg', label: { es: 'ilustración de mujer', eu: 'Emakume baten marrazkia' } },
      { id: 'joven018', image: 'joven018.jpg', label: { es: 'Ilustracion de mujer', eu: 'Emakume baten marrazkia' } },
      { id: 'joven019', image: 'joven019.jpg', label: { es: 'Ilustración mano', eu: 'Eskubaten marrazkia' } },
      {
        id: 'joven020',
        image: 'joven020.jpg',
        label: { es: 'Ilustación rostro mujer', eu: 'Emakume baten marrazkia' },
      },
      {
        id: 'joven021',
        image: 'joven021.jpg',
        label: { es: 'Ilustración mono con cascos', eu: 'Kaskodun tximino baten irudia' },
      },
      {
        id: 'joven022',
        image: 'joven022.jpg',
        label: { es: 'Ilustración leon vestido', eu: 'Jantzitako lehoi baten irudia' },
      },
      { id: 'joven023', image: 'joven023.jpg', label: { es: 'ilustración Pulpo', eu: 'Olagarro baten irudia' } },
      { id: 'joven024', image: 'joven024.jpg', label: { es: 'Ilustración color', eu: 'Koloreetako irudiak' } },
      { id: 'joven025', image: 'joven025.jpg', label: { es: 'Ilustración tigres', eu: 'Trigreen marrazkia' } },
      { id: 'joven026', image: 'joven026.jpg', label: { es: 'Ilustración gepardos', eu: 'Gepardoaren marrazkia' } },
      { id: 'joven027', image: 'joven027.jpg', label: { es: 'Ilustración night party', eu: 'Night party marrazkia' } },
      { id: 'joven028', image: 'joven028.jpg', label: { es: 'Ilustración mujer', eu: 'Emakume baten irudia' } },
      {
        id: 'joven029',
        image: 'joven029.jpg',
        label: { es: 'Ilustración baloncesto', eu: 'Saskibaloiko jokalariaren marrazkia' },
      },
      { id: 'joven030', image: 'joven030.jpg', label: { es: 'Ilustración boxeo', eu: 'Boxeolari baten marrazkia' } },
      {
        id: 'joven031',
        image: 'joven031.jpg',
        label: { es: 'Ilustración pajaro en moto', eu: 'Txori baten marrazkia motorrean' },
      },
      {
        id: 'joven032',
        image: 'joven032.jpg',
        label: { es: 'Ilustración pájaro en moto', eu: 'Txori baten marrazkia motorrean' },
      },
      { id: 'joven033', image: 'joven033.jpg', label: { es: 'Ilustración llama', eu: 'Llama baten marrazkia' } },
      { id: 'joven034', image: 'joven034.jpg', label: { es: 'Ilustración pinguino', eu: 'Pingüino baten marrazkia' } },
      { id: 'joven035', image: 'joven035.jpg', label: { es: 'Ilustración Flamenco', eu: 'Flamenko baten marrazkia' } },
      { id: 'joven036', image: 'joven036.jpg', label: { es: 'Ilustración joven', eu: 'Gatze baten irudia' } },
      { id: 'joven037', image: 'joven037.jpg', label: { es: 'Ilustración joven', eu: 'Gatze baten irudia' } },
      { id: 'joven038', image: 'joven038.jpg', label: { es: 'Ilustración en rojo', eu: 'Kolore gorrizko irudia' } },
      { id: 'joven039', image: 'joven039.jpg', label: { es: 'Ilustración ojos', eu: 'Begien marrazkia' } },
      { id: 'joven040', image: 'joven040.jpg', label: { es: 'Ilustración flores', eu: 'Loren marrazkia' } },
      { id: 'joven041', image: 'joven041.jpg', label: { es: 'Ilustración casa', eu: 'Etxeen marrazkia' } },
      { id: 'joven042', image: 'joven042.jpg', label: { es: 'Ilustración', eu: 'Iruadiak' } },
      { id: 'joven043', image: 'joven043.jpg', label: { es: 'Ilustración caras', eu: 'Aurpegien irudiak' } },
      { id: 'joven044', image: 'joven044.jpg', label: { es: 'Ilustración colores', eu: 'Koloretako irudiak' } },
      { id: 'joven045', image: 'joven045.jpg', label: { es: 'Ilustración', eu: 'Irudiak' } },
    ],
  },
]

export default {}
