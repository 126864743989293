import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { getBolUrlsLkConfig } from '../../../../../lib/lkWebConfig'
import CTALink from '../../../../CTALink/CTALink'
import { LkCard } from '../types'

export interface RequestCardButtonsProps {
  selectedCard: LkCard
}

const RequestCardButtons: FC<RequestCardButtonsProps> = ({ selectedCard }) => {
  const bolUrls = getBolUrlsLkConfig()
  const { t } = useTranslation()

  return (
    <div>
      <div className="lk-custom-card__action-buttons">
        {selectedCard && (
          <CTALink
            kind="button"
            link={bolUrls.customizeActualCard(selectedCard?.id)}
            title={t('widgetCustomizeYourCard.actionCustomizeActualCard')}
          />
        )}
        {selectedCard && (
          <CTALink
            kind="button"
            link={bolUrls.customizeNewCard(selectedCard?.id)}
            title={t('widgetCustomizeYourCard.actionCustomizeNewCard')}
          />
        )}
      </div>
      <p
        className="lk-custom-card__action-footer text-xsmall"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t('widgetCustomizeYourCard.footer'),
        }}
      />
    </div>
  )
}

export default RequestCardButtons
