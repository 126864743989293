import '../Widgets.scss'
import './WidgetTravelInsurance.scss'

import { Button } from '@lk/lk-design-system'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import LKApis from '../../../../services/lk-apis/LKApisProvider'
import {
  Country,
  Coverage,
  PatchTravelProposalArgs,
  PostTravelProposalArgs,
  TravelOffer,
} from '../../../../services/lk-apis/types'
import AccessibleModal from '../../../AccessibleModal/AccessibleModal'
import { DocWithSlug } from '../../../CTALink/CTALink'
import LoadingIndicator from '../../../LoadingIndicator/LoadingIndicator'
import TravelInsuranceOfferBox from './steps/TravelInsuranceChoiceBox'
import TravelInsuranceChoices from './steps/TravelInsuranceChoices'
import TravelInsuranceFinalForm from './steps/TravelInsuranceFinalForm'
import TravelInsuranceInitialForm from './steps/TravelInsuranceInitialForm'

export interface WidgetTravelInsuranceProps {
  legalLink: DocWithSlug
}

const WidgetTravelInsurance: FC<WidgetTravelInsuranceProps> = ({ legalLink }) => {
  const [loading, setLoading] = useState(false)
  const [sentOk, setSentOk] = useState(false)
  const { lkWebApi } = useContext(LKApis)
  const { t } = useTranslation()
  const [step, setStep] = useState(0)
  const [travelInfo, setTravelInfo] = useState<PostTravelProposalArgs>(null)
  const [travelProposalId, setTravelProposalId] = useState<string>(null)
  const [insuranceChoices, setInsuranceChoices] = useState<TravelOffer[]>([])
  const [selectedChoice, setSelectedChoice] = useState<TravelOffer>(null)
  const [countries, setCountries] = useState<Country[]>([])
  const [coverages, setCoverages] = useState<Coverage[]>([])
  const [apiError, setApiError] = useState(null)

  useEffect(() => {
    const fetchCountries = async () => {
      setLoading(true)
      try {
        const { data } = await lkWebApi.getTravelCountries()
        setCountries(data?.data?.countries || [])
      } catch (e) {
        setApiError(e.message)
      }
      setLoading(false)
    }

    const fetchCoverages = async () => {
      setLoading(true)
      try {
        const { data } = await lkWebApi.getTravelCoverages()
        setCoverages(data?.data?.coverages || [])
      } catch (e) {
        setApiError(e.message)
      }
      setLoading(false)
    }

    fetchCountries()
    fetchCoverages()
  }, [lkWebApi, setLoading])

  const nextStep = useCallback(() => {
    setStep(step + 1)
  }, [step])

  const onInitialFormSubmit = useCallback(
    async (initialData: PostTravelProposalArgs) => {
      setLoading(true)
      setTravelInfo(initialData)
      await lkWebApi
        .postTravelProposal(initialData)
        .then(({ data }) => {
          const { travelProposalID, travelOffers } = data.data
          setTravelProposalId(travelProposalID)
          setInsuranceChoices(travelOffers)
          nextStep()
        })
        .catch(() => {
          setApiError(t('widgetTravelInsurance.errors.genericError'))
        })
        .finally(() => setLoading(false))
    },
    [lkWebApi, nextStep, t],
  )

  const onChoiceSelected = useCallback(
    (choice: TravelOffer) => {
      setSelectedChoice(choice)
      nextStep()
    },
    [nextStep],
  )

  const onFinalFormSubmit = useCallback(
    async (finalData: PatchTravelProposalArgs) => {
      setLoading(true)
      await lkWebApi
        .patchTravelProposal(travelProposalId, finalData)
        .then(() => {
          setSentOk(true)
        })
        .catch(() => setApiError(t('widgetTravelInsurance.errors.genericError')))
        .finally(() => setLoading(false))
    },
    [lkWebApi, travelProposalId, t],
  )

  return (
    <div className="lk-calculator lk-widget-travel" data-testid="lk-widget-travel-insurance" aria-busy={loading}>
      <div className="lk-calculator__container row">
        <div className="lk-calculator__main col-md-12 p-0">
          <div className="lk-calculator__content">
            <div className="lk-calculator__intro">
              <div className="lk-calculator__title">{t('widgetTravelInsurance.title')}</div>
              <div className="lk-calculator__intro">
                <p className="strong">{t('widgetTravelInsurance.subtitle')}</p>
              </div>
              {step > 0 && (
                <Button
                  onClick={() => setStep(step - 1)}
                  size="sm"
                  icon="chevron-left"
                  label={t('calculators.previous')}
                  buttonRole="secondary"
                  className="lk-widget-travel__back"
                />
              )}
            </div>
            <div className="lk-calculator__choices">
              <LoadingIndicator loading={loading} />
              {step === 0 && (
                <TravelInsuranceInitialForm
                  countries={countries}
                  coverages={coverages}
                  initialValues={travelInfo}
                  onSubmit={onInitialFormSubmit}
                />
              )}
              {step === 1 && <TravelInsuranceChoices offers={insuranceChoices} onChoiceSelected={onChoiceSelected} />}
              {step === 2 && (
                <div className="row">
                  <div className="col-md-4 lk-widget-travel__final bg-berenjena-claro-4">
                    <TravelInsuranceOfferBox offer={selectedChoice} showButton={false} />
                  </div>
                  <div className="col-md-8">
                    <TravelInsuranceFinalForm
                      travelOffer={selectedChoice}
                      legalLink={legalLink}
                      onSubmit={onFinalFormSubmit}
                    />
                  </div>
                </div>
              )}
            </div>
            <p
              className="lk-widget-travel__footer"
              dangerouslySetInnerHTML={{ __html: t('widgetTravelInsurance.wantMoreInfo') }}
            />
          </div>
        </div>
      </div>
      <AccessibleModal
        heading={t('widgetTravelInsurance.successTitle')}
        active={sentOk}
        onClose={() => {
          setSentOk(false)
          setStep(0)
          setInsuranceChoices([])
          setSelectedChoice(null)
        }}
        fixed
      >
        <p>{t('widgetTravelInsurance.successMessage')}</p>
      </AccessibleModal>
      <AccessibleModal
        heading={t('widgetTravelInsurance.errors.apiErrorTitle')}
        active={!!apiError}
        onClose={() => {
          setApiError(null)
        }}
        fixed
      >
        <div>{apiError}</div>
      </AccessibleModal>
    </div>
  )
}

export default WidgetTravelInsurance
