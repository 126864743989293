import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import React, { FC } from 'react'

import * as SanitySchema from '../../lib/types/sanity-schema'

export type SpacerProps = Omit<SanitySchema.Spacer, '_type'>

const Spacer: FC<SpacerProps> = () => {
  const breakpoints = useBreakpoint()
  return breakpoints.xs || breakpoints.sm || breakpoints.md ? '' : <div role="separator" />
}

export default Spacer
