import './Banner.scss'

import classNames from 'classnames'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import React, { FC, ReactElement, useEffect, useState } from 'react'

import * as SanitySchema from '../../lib/types/sanity-schema'
import AccessibleCarousel from '../AccessibleCarousel/AccessibleCarousel'
import useLayoutOptions from '../Layout/hooks/useLayoutOptions'
import useBannerUtils, { SlideContext } from './hooks/useBannerUtils'

export type BannerProps = Omit<SanitySchema.Banner, '_type' | 'hero'> & {
  hero: SanitySchema.SanityKeyed<SanitySchema.Hero>
}

const Banner: FC<BannerProps> = ({ hero, aspectRatio }) => {
  const [carouselId, setCarouselId] = useState('banner')
  const [slides, setSlides] = useState<Array<ReactElement>>([])
  const { prepareSlide, processScheduledBanner, isSlideVisible } = useBannerUtils()
  const breakpoints = useBreakpoint()
  const { hideHeaderAndFooter, isLkModal } = useLayoutOptions()

  useEffect(() => {
    const rawSlides = processScheduledBanner(hero.content)
    const id = `banner-${rawSlides?.map((s) => s._key).join('-')}`
    const context: SlideContext = {
      breakpoints,
      isClnetTrue: hideHeaderAndFooter,
      isLkModal,
    }
    const newSlides = rawSlides
      ?.filter((slide) => isSlideVisible(slide, context))
      .map((slide) => prepareSlide(slide, id))
      .filter(Boolean)

    setSlides(newSlides)
    setCarouselId(id)
  }, [hero, hideHeaderAndFooter, isLkModal, prepareSlide, processScheduledBanner, breakpoints])

  return (
    slides?.length > 0 && (
      <section className={classNames('lk-banner-section', !!aspectRatio && 'has-aspect-ratio')}>
        <div className={classNames('container', aspectRatio)}>
          <AccessibleCarousel autoSlideSeconds={hero.autoSlideSeconds || 30} id={carouselId} role="banner">
            {slides}
          </AccessibleCarousel>
        </div>
      </section>
    )
  )
}

export default Banner
