import './Information.scss'

import React from 'react'
import { useTranslation } from 'react-i18next'

const images = {
  boneFracture: '/Components/WidgetInsuranceCalculator/bajadiaria.png',
  hospitalization: '/Components/WidgetInsuranceCalculator/hospitalizacion.png',
  dailyAsistance: '/Components/WidgetInsuranceCalculator/calendario-resize.png',
  deceases: '/Components/WidgetInsuranceCalculator/fallecimiento.png',
}

const options = ['boneFracture', 'hospitalization', 'dailyAsistance', 'deceases']

const SuperLKInformation = () => {
  const { t } = useTranslation()

  return (
    <div className="lk-calculator__aside col-lg-4 col-md-12 bg-berenjena-claro-4">
      <div className="advantages-box advantages-box__superLK">
        <p
          className="text-large"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t('widgetInsuranceCalculator.advantagesSuperLk.title'),
          }}
        />
        <p className="text-large">{t('widgetInsuranceCalculator.advantagesSuperLk.includes')}</p>
        {options.map((advantage) => (
          <div key={advantage} className="superLKAdvantages">
            <img className="superLKAdvantages__icon" src={images[advantage]} alt="" />
            <div className="superLKAdvantages__text">
              <p
                className="superLKAdvantages__text-upper text-large"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t(`widgetInsuranceCalculator.advantagesSuperLk.${advantage}`),
                }}
              />
              <p className="superLKAdvantages__text-under ">
                {t(`widgetInsuranceCalculator.advantagesSuperLk.${advantage}Subtitle`, '')}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SuperLKInformation
