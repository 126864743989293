import './WidgetInsuranceCalculator.scss'

import { Button } from '@lk/lk-design-system'
import React, { FC, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import LKApis from '../../../../services/lk-apis/LKApisProvider'
import {
  InsuranceAccidentQuotaModules,
  InsuranceAccidentQuotaProfessions,
  PatchInsuranceAccidentQuotaArgs,
  PatchInsuranceAccidentQuotaBudget,
  PatchInsuranceAccidentQuotaCustomer,
  PatchInsuranceAccidentQuotaOptions,
} from '../../../../services/lk-apis/types'
import AccessibleModal from '../../../AccessibleModal/AccessibleModal'
import { DocWithSlug } from '../../../CTALink/CTALink'
import LoadingIndicator from '../../../LoadingIndicator/LoadingIndicator'
import { InsuranceCalculatorStep } from '../WidgetBolNewUser/common/types'
import ConfortInformation from './information/ConfortInformation'
import InitialInformation from './information/InitialInformation'
import SuperLKInformation from './information/SuperLKInformation'
import InsuranceCalculatorConfort from './steps/InsuranceCalculatorConfort'
import InsuranceCalculatorFinalForm from './steps/InsuranceCalculatorFinalForm'
import InsuranceCalculatorInitialForm, { InitialFormArgs } from './steps/InsuranceCalculatorInitialForm'
import InsuranceCalculatorSuperLK from './steps/InsuranceCalculatorSuperLK'

export interface WidgetInsuranceCalculatorProps {
  legalLink: DocWithSlug
  legalLinkPreContractual: DocWithSlug
}

const WidgetInsuranceCalculator: FC<WidgetInsuranceCalculatorProps> = ({ legalLink, legalLinkPreContractual }) => {
  const { t } = useTranslation()
  const { lkWebApi } = useContext(LKApis)
  const [age, setAge] = useState(16)
  const [birthDate, setBirthDate] = useState(new Date())
  const [loading, setLoading] = useState(false)
  const [apiError, setApiError] = useState(null)
  const [petitionId, setPetitionId] = useState('')
  const [selectedDeseace, setSelectedDecease] = useState(false)
  const [sentSuccessfully, setSentSuccessfully] = useState(false)
  const [selectedProfession, setSelectedProfession] = useState(0)
  const [step, setStep] = useState<InsuranceCalculatorStep>(InsuranceCalculatorStep.Age)
  const [currentBudget, setCurrentBudget] = useState<PatchInsuranceAccidentQuotaBudget>()
  const [selectedBudget, setSelectedBudget] = useState<InsuranceAccidentQuotaModules>()
  const [availableModules, setAvailableModules] = useState<InsuranceAccidentQuotaModules[]>()
  const [availableProfessions, setAvailableProfessions] = useState<InsuranceAccidentQuotaProfessions[]>()

  const onInitialFormSubmit = useCallback(
    async (initialData: InitialFormArgs) => {
      setLoading(true)
      setBirthDate(initialData.birthDate)
      setAge(initialData.age)
      await lkWebApi
        .postInsuranceAccidentQuota({ age: initialData.age })
        .then(({ data }) => {
          const { modules, professions } = data.data
          setAvailableModules(modules)
          setAvailableProfessions(professions)
          setStep(initialData.age < 65 ? InsuranceCalculatorStep.Confort : InsuranceCalculatorStep.SuperLK)
          setPetitionId(data.data.petitionId)
        })
        .catch(() => {
          setApiError(t('widgetInsuranceCalculator.errors.genericError'))
        })
        .finally(() => setLoading(false))
    },
    [lkWebApi, t],
  )

  const onGenerateBudgetSubmit = useCallback(
    async (values: PatchInsuranceAccidentQuotaArgs, option: PatchInsuranceAccidentQuotaOptions) => {
      setLoading(true)
      await lkWebApi
        .patchInsuranceAccidentQuota({ ...values, petitionId }, option)
        .then(({ data }) => {
          setSelectedDecease(values.includeDecease)
          setSelectedProfession(values.professionCode)
          setCurrentBudget(data.data)
          setSelectedBudget(data.data.budgetedModule)
          setStep(InsuranceCalculatorStep.Contact)
        })
        .catch(() => {
          setApiError(t('widgetInsuranceCalculator.errors.genericError'))
        })
        .finally(() => setLoading(false))
    },
    [lkWebApi, petitionId, t],
  )

  const onChangeBudget = (newSelectedBudget: InsuranceAccidentQuotaModules | undefined) => {
    setSelectedBudget(newSelectedBudget)
  }

  const onContactSubmit = useCallback(
    async (values: PatchInsuranceAccidentQuotaCustomer, option: PatchInsuranceAccidentQuotaOptions) => {
      setLoading(true)
      const contactValues: PatchInsuranceAccidentQuotaArgs = {
        petitionId,
        moduleCode: selectedBudget?.moduleCode,
        professionCode: selectedProfession,
        includeDecease: selectedDeseace,
        selectedOfferData: {
          selectedModuleCode: selectedBudget?.moduleCode,
          selectedModuleImport: selectedBudget?.moduleImport.amount,
          selectedModuleQuota: selectedBudget?.anualQuota.amount,
        },
        customerContractingData: values,
      }
      await lkWebApi
        .patchInsuranceAccidentQuota(contactValues, option)
        .then(({ data }) => {
          setCurrentBudget(data.data)
          setSentSuccessfully(true)
        })
        .catch(() => {
          setApiError(t('widgetInsuranceCalculator.errors.genericError'))
        })
        .finally(() => setLoading(false))
    },
    [lkWebApi, petitionId, selectedBudget, selectedDeseace, selectedProfession, t],
  )

  const resetForm = () => {
    setStep(InsuranceCalculatorStep.Age)
    setBirthDate(new Date())
    setPetitionId('')
    setSelectedProfession(0)
    setSelectedDecease(false)
    setAvailableModules([])
    setAvailableProfessions([])
    setCurrentBudget(undefined)
  }

  const handleBackButton = () => {
    if (step === InsuranceCalculatorStep.Contact && age >= 65) {
      setStep(InsuranceCalculatorStep.SuperLK)
      setCurrentBudget(undefined)
    } else if (step === InsuranceCalculatorStep.Contact && age < 65) {
      setStep(InsuranceCalculatorStep.Confort)
      setCurrentBudget(undefined)
    } else {
      setStep(InsuranceCalculatorStep.Age)
      resetForm()
    }
  }

  return (
    <div
      className="lk-calculator lk-widget-insurance-calculator"
      data-testid="lk-widget-insurance-calculator"
      aria-busy={loading}
    >
      <div className="lk-calculator__container row">
        <LoadingIndicator loading={loading} />
        <div
          className={`lk-calculator__main ${
            step === InsuranceCalculatorStep.Contact ? 'col-md-12' : 'col-lg-8 col-md-12'
          }`}
        >
          <div className="lk-calculator__content">
            <div className="lk-calculator__intro">
              <div className="lk-calculator__title">{t('widgetInsuranceCalculator.title')}</div>
              {step !== InsuranceCalculatorStep.Contact && (
                <div className="lk-calculator__intro">
                  <p className="strong">
                    {t(`widgetInsuranceCalculator.subtitle.${step}`, t('widgetInsuranceCalculator.subtitle.generic'))}
                  </p>
                </div>
              )}
              {step !== InsuranceCalculatorStep.Age && (
                <Button
                  onClick={handleBackButton}
                  size="sm"
                  icon="chevron-left"
                  label={t('calculators.previous')}
                  buttonRole="secondary"
                  className="lk-widget-insurance-calculator__back"
                />
              )}
            </div>

            <div className="lk-calculator__choices">
              {step === InsuranceCalculatorStep.Age && (
                <InsuranceCalculatorInitialForm onSubmit={onInitialFormSubmit} />
              )}
              {step === InsuranceCalculatorStep.Confort && (
                <InsuranceCalculatorConfort
                  previousBirthDate={birthDate}
                  modules={availableModules}
                  professions={availableProfessions}
                  buttonRole={currentBudget ? 'secondary' : 'primary'}
                  onSubmit={onGenerateBudgetSubmit}
                />
              )}
              {step === InsuranceCalculatorStep.SuperLK && (
                <InsuranceCalculatorSuperLK
                  previousBirthDate={birthDate}
                  modules={availableModules}
                  onSubmit={onGenerateBudgetSubmit}
                />
              )}
            </div>
            {step === InsuranceCalculatorStep.Contact && (
              <InsuranceCalculatorFinalForm
                currentBudget={currentBudget}
                selectedBudget={selectedBudget}
                onSubmit={onContactSubmit}
                onChangeBudget={onChangeBudget}
                legalLink={legalLink}
                legalLinkPreContractual={legalLinkPreContractual}
              />
            )}
            {step === InsuranceCalculatorStep.Age && (
              <div className="lk-widget-insurance-calculator__footer">
                <p className="text-xsmall">{t(`widgetInsuranceCalculator.legalText`)}</p>
              </div>
            )}
          </div>
        </div>
        {step === InsuranceCalculatorStep.Age && <InitialInformation />}
        {!currentBudget && step === InsuranceCalculatorStep.Confort && <ConfortInformation />}
        {!currentBudget && step === InsuranceCalculatorStep.SuperLK && <SuperLKInformation />}
      </div>
      <AccessibleModal
        heading={t('widgetInsuranceCalculator.successTitle')}
        active={sentSuccessfully}
        size="medium"
        onClose={() => {
          setSentSuccessfully(false)
          resetForm()
        }}
        fixed
      >
        <p>{t('widgetInsuranceCalculator.successMessage')}</p>
      </AccessibleModal>
      <AccessibleModal
        heading={t('widgetInsuranceCalculator.errors.apiErrorTitle')}
        active={!!apiError}
        size="medium"
        onClose={() => {
          setApiError(null)
        }}
        fixed
      >
        <div>{apiError}</div>
      </AccessibleModal>
    </div>
  )
}

export default WidgetInsuranceCalculator
