import './AccessibleModal.scss'

import { Modal } from '@lk/lk-design-system'
import { ModalButtonProps } from '@lk/lk-design-system/dist/components/modal/modal'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

export interface AccessibleModalProps {
  className?: string
  id?: string
  heading?: string
  closeButtonRole?: 'primary' | 'secondary'
  buttons?: ModalButtonProps[]
  onClose?: () => void
  size?: string
  active?: boolean
  fixed?: boolean
  children?: ReactNode | ReactNode[]
}

const AccessibleModal: React.FC<AccessibleModalProps> = ({
  className,
  id,
  heading,
  buttons,
  closeButtonRole,
  onClose,
  size,
  active,
  fixed,
  children,
}) => {
  const { t } = useTranslation()
  const defaultButtons: ModalButtonProps[] = [
    {
      onClick: onClose,
      role: closeButtonRole,
      label: t('common.close'),
    },
  ]
  return (
    <Modal
      className={`${className} accessible-modal`}
      id={id}
      heading={heading}
      buttons={buttons && buttons.length > 0 ? buttons : defaultButtons}
      onClose={onClose}
      size={size || 'medium'}
      active={active}
      fixed={fixed}
    >
      {children}
    </Modal>
  )
}

AccessibleModal.defaultProps = {
  className: undefined,
  id: undefined,
  heading: '',
  buttons: [],
  closeButtonRole: 'secondary',
  onClose: undefined,
  size: undefined,
  active: undefined,
  fixed: undefined,
  children: undefined,
}

export default AccessibleModal
