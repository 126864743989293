import React, { FC, PropsWithChildren, useState } from 'react'

import * as SanitySchema from '../../lib/types/sanity-schema'

export interface ContentFilterContextProps {
  filterKeywords: string[]
  setFilterKeywords: (keywords: string[]) => void
  isVisibleForFilterKeywords: (componentKeywords: SanitySchema.Keyword[]) => boolean
}

const ContentFilterContext = React.createContext<ContentFilterContextProps>({
  filterKeywords: [],
  setFilterKeywords: () => {},
  isVisibleForFilterKeywords: () => true,
})

export const ContentFilterProvider: FC<PropsWithChildren> = ({ children }) => {
  const [filterKeywords, setFilterKeywords] = useState<string[]>([])

  const isVisibleForFilterKeywords = (componentKeywords: SanitySchema.Keyword[]) => {
    if (!componentKeywords || componentKeywords.length === 0) {
      return true
    }

    return filterKeywords.reduce(
      (previousValue, keyword) => previousValue && componentKeywords.find((k) => k.keyword === keyword) !== undefined,
      true,
    )
  }

  const value: ContentFilterContextProps = {
    filterKeywords,
    setFilterKeywords,
    isVisibleForFilterKeywords,
  }

  return <ContentFilterContext.Provider value={value}>{children}</ContentFilterContext.Provider>
}

export default ContentFilterContext
