import React, { FC } from 'react'

export interface ErrorListProps {
  errors: Array<{ message: string }>
}

const Errors: FC<ErrorListProps> = ({ errors }) => (
  <div>
    <h2>GraphQL Error</h2>
    {console.log(errors)}
    {errors.map((error) => (
      <pre key={error.message}>{error.message}</pre>
    ))}
  </div>
)

export default Errors
