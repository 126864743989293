import { Button } from '@lk/lk-design-system'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import React, { FC, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PostInsuranceAccidentQuota } from '../../../../../services/lk-apis/types'
import LocalizedDatePicker from '../../../../LocalizedDatePicker/LocalizedDatePicker'

export interface InitialFormArgs extends PostInsuranceAccidentQuota {
  birthDate: Date
}
export interface InitialFormProps {
  onSubmit: (values: InitialFormArgs) => void
}

const InsuranceCalculatorInitialForm: FC<InitialFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const { language } = useContext(I18nextContext)
  const [birthDate, setBirthDate] = useState(new Date())
  const [ageError, setAgeError] = useState(null)

  const getUserAge = (userBirthDate: Date) => {
    const today = new Date()
    const birthYear = userBirthDate.getFullYear()
    const birthMonth = userBirthDate.getMonth()
    const birthDay = userBirthDate.getDate()

    let age = today.getFullYear() - birthYear

    const isBirthdayPassedThisYear =
      today.getMonth() > birthMonth || (today.getMonth() === birthMonth && today.getDate() >= birthDay)

    if (!isBirthdayPassedThisYear) {
      age -= 1
    }

    return age
  }

  const handleSubmit = useCallback(() => {
    const numericAge = getUserAge(birthDate)
    if (!birthDate) {
      setAgeError(t('widgetInsuranceCalculator.errors.age'))
      return
    }
    if (!(numericAge >= 16 && numericAge <= 75)) {
      setAgeError(t('widgetInsuranceCalculator.errors.ageBetween'))
      return
    }
    onSubmit({ age: numericAge, birthDate })
  }, [birthDate, onSubmit, t])

  return (
    <>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <LocalizedDatePicker
            language={language}
            label={t('widgetInsuranceCalculator.age')}
            onChange={(date) => setBirthDate(new Date(date?.toISOString().replaceAll(/(T.*)/g, `T00:00:00Z`)))}
            error={!!ageError}
            errorMessage={ageError}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 lk-widget-insurance-calculator__actions">
          <Button
            type="button"
            buttonRole="primary"
            label={t('widgetInsuranceCalculator.continue')}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  )
}

export default InsuranceCalculatorInitialForm
