import classNames from 'classnames'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import * as SanitySchema from '../../lib/types/sanity-schema'
import CTALink from '../CTALink/CTALink'
import Figure from '../Figure/Figure'
import TextWithIllustration from '../TextWithIllustration/TextWithIllustration'

export interface DecisionTreeLeaveCardProps {
  node: SanitySchema.DecisionTreeLeave
}

const DecisionTreeLeaveCard: FC<DecisionTreeLeaveCardProps> = ({ node }) => {
  const { t } = useTranslation()
  const { title, body, cta } = node
  const breakpoints = useBreakpoint()
  return (
    <div
      className={classNames('decisionTree-card', 'decisionTree-card-leave', {
        'decisionTree-card-leave__recommended': node.isRecommended,
      })}
      style={{ order: breakpoints.xs && node.isRecommended ? 1 : 2 }}
    >
      {node.isRecommended && (
        <div className="decisionTree-card-leave__recommended-label">
          <span>{t('decisionTree.weRecommendYou')}</span>
        </div>
      )}
      {body.illustration && (
        <div>
          <Figure node={body.illustration.image as SanitySchema.MainImage} args={{ height: 140 }} />
        </div>
      )}
      <div className="decisionTree-card__header">{title}</div>
      <div className="decisionTree-card__body">
        <TextWithIllustration text={body.text} layout={body.layout} />
      </div>
      <div className="decisionTree-card__footer">
        <CTALink title={cta.title} link={cta.link} route={cta.route} pageRoute={cta.pageRoute} kind="button" />
      </div>
    </div>
  )
}

export default DecisionTreeLeaveCard
