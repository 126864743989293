import './ContentFilter.scss'

import { Button } from '@lk/lk-design-system'
import React, { FC, useCallback, useContext, useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as SanitySchema from '../../lib/types/sanity-schema'
import ContentFilterField from './ContentFilterField'
import ContentFilterContext from './ContentFilterProvider'

export type ContentFilterProps = Omit<SanitySchema.ContentFilter, '_type'>

const ContentFilter: FC<ContentFilterProps> = ({ title, filterFields, backgroundColor }) => {
  const { t } = useTranslation()
  const [reRender, forceUpdate] = useReducer((x) => x + 1, 0)
  const [filter, setFilter] = useState({})
  const { filterKeywords, setFilterKeywords } = useContext(ContentFilterContext)
  const onFilterChange = useCallback((name: string, value: string) => {
    setFilter((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, [])

  useEffect(() => {
    const activeKeywords = Object.values(filter).filter((e) => !!e) as string[]
    setFilterKeywords(activeKeywords)
  }, [filter, setFilterKeywords])

  return (
    <div className="lk-content-filter" role="search">
      <div className={`lk-content-filter__content ${backgroundColor}`}>
        <div className="h3">{title}</div>
        <div className="lk-content-filter__options">
          {filterFields.map((field, index) => {
            const name = `filter_${index}`
            return (
              <ContentFilterField
                key={`${field._key}_${reRender}`}
                name={name}
                label={field.label}
                options={field.options}
                fieldType={field.fieldType}
                placeholder={field.placeholder}
                onFilterChange={onFilterChange}
              />
            )
          })}
        </div>
      </div>
      <Button
        buttonType="compact"
        buttonRole="secondary"
        onClick={() => {
          setFilter({})
          forceUpdate()
        }}
        className={`${backgroundColor} ${!filterKeywords?.length ? 'hidden' : ''}`}
        aria-hidden={!filterKeywords?.length}
      >
        {t('widgetContentFilter.clearFilter')}
      </Button>
    </div>
  )
}

export default ContentFilter
