import { ApiErrorResponse, PinStatusCodes } from '../../../../../services/lk-apis/types'
import { BolStep } from './types'

export type BolStepTypes = 'identifier' | 'becomeAClient' | 'register' | 'recover'

const isValidField = (name: string, value: string): boolean => {
  const regexValidations = {
    otp: /^[\da-z]{6}$/i,
    name: /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/,
    surname: /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/,
    address: /^(?:[A-Za-z0-9ñÑáéíóúÁÉÍÓÚüÜªºªº,.\s/]+)$/,
    city: /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/,
    email: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i,
    postalCode: /^([0-9]{5})$/,
    identifier: /^([0-9A-Z]{4,10})$/i,
    mobile: /^[67]\d{8}$/,
    cardNumber: /^(\d{4}\s){3}\d{4}$/,
    cvv2: /^\d{3}$/,
    expirationDate: /^(0[1-9]|1[0-2])\/([2-9][0-9])$/,
  }

  const normalizedValue = name === 'mobile' ? value.replaceAll(/\s/g, '') : value.trim()

  const regex = regexValidations[name]
  return regex?.test(normalizedValue) || false
}

const getApiErrorMessages = (response: ApiErrorResponse): string[] => {
  let errors = []
  const apiErrors = response?.errors
  if (apiErrors?.title) {
    errors.push(apiErrors.title)
  }
  const fieldErrors = apiErrors?.invalidParams?.map((i) => `${i?.errorField}: ${i?.errorDescription}`)
  if (fieldErrors) {
    errors = [...errors, ...fieldErrors]
  }
  return errors
}

const formatMobile = (input: string): string =>
  input
    ?.replace(/[^\d|^+]/g, '')
    .replace(/^(\d{1,3})?(\d{1,3})?(\d{1,3})?$/g, (_: string, g1: string, g2: string, g3: string) => {
      const formatted = `${g1 ? `${g1}` : ''}${g2 ? ` ${g2}` : ''}${g3 ? ` ${g3}` : ''}`
      return formatted || input
    })

const formatCardNumber = (input: string): string =>
  input
    ?.replace(/[^\d|^+]/g, '')
    .replace(/^(\d{1,4})?(\d{1,4})?(\d{1,4})?(\d{1,4})?$/g, (_: string, g1: string, g2: string, g3: string, g4) => {
      const formatted = `${g1 || ''}${g2 ? ` ${g2}` : ''}${g3 ? ` ${g3}` : ''}${g4 ? ` ${g4}` : ''}`
      return formatted || input
    })

const splitMobile = (mobile: string): { prefix: string; mobile: string } => {
  if (!mobile) return { prefix: '', mobile: '' }
  return {
    prefix: '0034',
    mobile: mobile.replaceAll(/\s/g, '').replace(/^(\+34)?(\d{9})$/, (_: string, g1: string, g2: string) => g2),
  }
}

const statusCodeToBolStep = (statusCode: number): BolStep => {
  if (PinStatusCodes.LessThanAge14 === statusCode) {
    return BolStep.FinishLessThan14
  }

  if (PinStatusCodes.BetweenAge14and17 === statusCode) {
    return BolStep.FinishBetween14And17
  }

  if (PinStatusCodes.NoCardNoBol === statusCode) {
    return BolStep.BolRegisterWithoutCCForm
  }

  if (PinStatusCodes.CardNoBol === statusCode) {
    return BolStep.BolRegisterWithCCForm
  }

  if (PinStatusCodes.NoCardWithBol === statusCode) {
    return BolStep.FinishNoCardWithBol
  }

  if (PinStatusCodes.CardWithBol === statusCode) {
    return BolStep.BolRecoverPassWithCc
  }

  if (PinStatusCodes.FinishBlockedByOffice === statusCode) {
    return BolStep.FinishBlockedByOffice
  }

  throw new Error(`Unknown PIN statusCode: ${statusCode}`)
}

const useBolUtils = () => ({
  isValidField,
  getApiErrorMessages,
  getFieldFormatter: (name) => {
    const knownFormatters = { mobile: formatMobile, cardNumber: formatCardNumber }
    return knownFormatters[name] || ((input: string) => input)
  },
  getFieldNormalizer: (name) => {
    const knownNormalizers = { mobile: (d) => d.replaceAll(/\s/g, ''), cardNumber: (d) => d.replaceAll(/\s/g, '') }
    return knownNormalizers[name] || ((input: string) => input)
  },
  splitMobile,
  statusCodeToBolStep,
})

export default useBolUtils
