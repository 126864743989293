import './BannerFigureWithText.scss'

import React, { ReactElement } from 'react'

import { BannerImageProps } from '../../types'

export const bannerImageProps: BannerImageProps = {
  sm: {
    width: 358,
    height: 253,
  },
  md: {
    width: 1024,
    height: 410,
  },
  lg: {
    width: 779,
    height: 576,
  },
}

export interface SlideFigureWithTextProps {
  title: string
  body: ReactElement
  footer: ReactElement
  cta: ReactElement<HTMLButtonElement>
  img: ReactElement<HTMLImageElement>
}

const BannerFigureWithText: React.FC<SlideFigureWithTextProps> = ({ title, body, footer, img, cta }) => (
  <div className="bannerFigureWithText">
    {img && <figure className="slide-image">{img}</figure>}
    <span className="slide-description">
      {title && <p className="lk-hero__heading">{title}</p>}
      {body && <div className="lk-hero__body">{body}</div>}
      {cta && <div className="lk-hero__actions">{cta}</div>}
      {footer && <div className="lk-hero__footer">{footer}</div>}
    </span>
  </div>
)

export default BannerFigureWithText
