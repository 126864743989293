import './WidgetIFrame.scss'

import React, { FC } from 'react'

export type WidgetIframeProps = {
  src: string
  title: string
}

const WidgetIframe: FC<WidgetIframeProps> = ({ src, title }) => (
  <div className="widget-iframe" data-testid="lk-widget-iframe">
    <iframe
      src={src}
      title={title}
      allowFullScreen
      width="100%"
      height="100%"
      referrerPolicy="no-referrer-when-downgrade"
    />
  </div>
)

export default WidgetIframe
