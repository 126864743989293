import axios from 'axios'
import { graphql, useStaticQuery } from 'gatsby'
import { useEffect, useState } from 'react'

const blogFeedCacheQuery = graphql`
  query BlogFeedCache {
    allFile(filter: { name: { regex: "/blogFeed.*/i" }, extension: { eq: "json" } }) {
      nodes {
        id
        name
        publicURL
      }
    }
  }
`
export interface BlogPost {
  guid: string
  link: string
  title: string
  pubDate: string
  categories: string[]
}

export interface BlogFeed {
  items: BlogPost[]
}

const useBlogFeedCache = () => {
  const { allFile } = useStaticQuery(blogFeedCacheQuery) || { allFile: { nodes: [] } }
  const [blogId, setBlogId] = useState('')
  const [blog, setBlog] = useState<BlogFeed>()

  useEffect(() => {
    let mounted = true

    const loadBlog = async () => {
      const client = axios.create()
      if (!blogId) {
        return
      }
      const blogsCache = allFile?.nodes || []
      const feedCache = blogsCache.find((n) => n.name === `blogFeed-${blogId}`)
      const feedCacheUrl = feedCache?.publicURL
      let newBlog
      try {
        const { data } = await client.get(feedCacheUrl, {
          headers: {
            'Allow-Origin': '*',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        })
        newBlog = data
      } catch (e) {
        console.warn('can not get cache feed', feedCacheUrl, e)
      }
      if (mounted) {
        setBlog(newBlog)
      }
    }
    loadBlog()
    return () => {
      mounted = false
    }
  }, [allFile.nodes, blogId])

  const loadBlogById = (id: string): void => {
    setBlogId(id)
  }
  return { loadBlogById, blog }
}

export default useBlogFeedCache
