import './WidgetCustomizeYourCard.scss'

import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AccessibleTabs from '../../../AccesibleTabs/AccessibleTabs'
import AccessibleSelect from '../../../AccessibleSelect/AccessibleSelect'
import { LaboralKutxaCards } from './cards_config'
import CardsGrid from './components/CardsGrid'
import CardsSlider from './components/CardsSlider'
import { LkCardCategory } from './types'

const WidgetCustomizeYourCard: FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<LkCardCategory>(LaboralKutxaCards[0])
  const [allCategoriesOptions, setAllCategoriesOptions] = useState([])
  const { t } = useTranslation()
  const { language } = useContext(I18nextContext)
  const breakpoints = useBreakpoint()

  const allCardsCategoriesOptions = allCategoriesOptions?.map((category) => ({
    label: category.label,
    value: category.id,
  }))

  const handleCategoriesChange = useCallback((value) => {
    setSelectedCategory(LaboralKutxaCards.find((c) => c.id === value))
  }, [])

  useEffect(() => {
    const allCategories = LaboralKutxaCards.map((cat) => ({
      id: cat.id,
      label: cat.label[language],
      value: cat.id,
      active: cat.id === selectedCategory?.id,
    }))
    setAllCategoriesOptions(allCategories)
  }, [language, selectedCategory])

  return (
    <div className="lk-custom-card" data-testid="lk-widget-customize-your-card">
      <h2
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: t('widgetCustomizeYourCard.title') }}
      />
      <div className="row">
        <div className="col-lg-12 col-sm-12">
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: t('widgetCustomizeYourCard.header') }}
            className="text"
          />
        </div>
      </div>
      {!breakpoints.xs && (
        <AccessibleTabs
          tabs={allCategoriesOptions}
          onChange={(selected) => {
            setSelectedCategory(LaboralKutxaCards.find((c) => c.id === selected.value))
          }}
          selectedId={selectedCategory.id}
        />
      )}
      {breakpoints.xs && (
        <div className="row">
          <div className="col-12">
            <AccessibleSelect
              name="allCardsCategories"
              label={t('widgetCustomizeYourCard.chooseCategory')}
              options={allCardsCategoriesOptions}
              placeholder="Categorías"
              onItemSelected={handleCategoriesChange}
              initialValue={selectedCategory.id}
            />
          </div>
        </div>
      )}
      <div className="lk-custom-card__tab-content">
        {breakpoints.xs && <CardsSlider cards={selectedCategory.cards} />}
        {!breakpoints.xs && <CardsGrid cards={selectedCategory.cards} />}
      </div>
    </div>
  )
}
export default WidgetCustomizeYourCard
