import { Button } from '@lk/lk-design-system'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import useI18nUtils from '../../../../../hooks/useI18nUtils'
import { TravelOffer } from '../../../../../services/lk-apis/types'

interface TravelInsuranceOfferBoxProps {
  offer: TravelOffer
  onChoiceSelected?: (choice: TravelOffer) => void
  showButton?: boolean
}

const TravelInsuranceOfferBox: FC<TravelInsuranceOfferBoxProps> = ({ showButton, offer, onChoiceSelected }) => {
  const { id, definition, properties, amount } = offer
  const { t } = useTranslation()
  const { formatCurrency } = useI18nUtils()
  return (
    <div className="lk-widget-travel-choice">
      <div className="lk-widget-travel-choice__header text-xlarge strong text-uppercase">{definition}</div>
      <div className="lk-widget-travel-choice__price text-xlarge strong">{formatCurrency(amount.amount)}</div>
      <div className="lk-widget-travel-choice__body">
        <ul>
          {properties?.map((property, index) => {
            const key = `travel_${id}_${index}`
            return <li key={key}>{property}</li>
          })}
        </ul>
      </div>
      {showButton && (
        <Button
          className="lk-widget-travel-choice__footer"
          buttonType="button"
          onClick={() => onChoiceSelected(offer)}
          label={t('widgetTravelInsurance.selectOfferButton')}
        />
      )}
    </div>
  )
}

TravelInsuranceOfferBox.defaultProps = {
  onChoiceSelected: () => {},
  showButton: true,
}

export default TravelInsuranceOfferBox
