import React, { useCallback, useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import LKApis from '../../../../../services/lk-apis/LKApisProvider'
import { PatchRecoverPinWithCc } from '../../../../../services/lk-apis/types'
import AccessibleModal from '../../../../AccessibleModal/AccessibleModal'
import BolApiErrorMessage from '../../../common/BolApiErrorMessage'
import LkCaptcha, { LkCaptchaRef } from '../../../common/LkCaptcha'
import BaseForm, { FormFields } from '../common/BaseForm'
import { BolFormProps, BolStep } from '../common/types'

const formFields: FormFields[] = ['name', 'surname', 'cardNumber', 'cvv2', 'expirationDate']

export interface BolRecoverPinWithCcProps extends BolFormProps {
  petitionId: string
}

const BolRecoverPinWithCc: React.FC<BolRecoverPinWithCcProps> = ({ identifier, petitionId, onSuccess }) => {
  const captchaRef = useRef<LkCaptchaRef>()
  const { lkWebApi } = useContext(LKApis)
  const { t } = useTranslation()
  const [success, setSuccess] = useState(false)
  const [errorCode, setErrorCode] = useState<string>(null)
  const [errorStatus, setErrorStatus] = useState<number>(null)

  const handleSubmit = useCallback(
    async (data) => {
      const captchaToken = await captchaRef.current.getCaptchaToken()
      const apiData: PatchRecoverPinWithCc = {
        petitionId,
        cardNumber: data.cardNumber,
        expirationDate: data.expirationDate,
        cvv2: data.cvv2,
        name: data.name,
        surname: data.surname,
      }
      try {
        const response = await lkWebApi.customerManagement.patchRecoverPinWithCc(apiData, captchaToken)
        if (response?.status === 200) {
          setSuccess(true)
        } else {
          setSuccess(false)
          setErrorCode('UNEXPECTED_STATUS_CODE')
        }
        setErrorStatus(response?.status || -1)
      } catch (error) {
        setErrorStatus(error?.response?.status || -1)
        setErrorCode(error?.response?.data?.errors?.detail || 'UNKNOWN_ERROR')
      }
    },
    [lkWebApi.customerManagement, petitionId],
  )

  return (
    <div data-testid="bol-form-recover-pin-with-cc">
      <p className="lk-bol-new-user__title">{t('widgetBolNewUser.recoverPinWithCcIntro')}</p>
      <BaseForm
        identifier={identifier}
        onFormSubmit={handleSubmit}
        fields={formFields}
        submitLabel={t('widgetBolNewUser.recoverPinWithCcButton')}
        showLegalCheckbox
      />
      <LkCaptcha ref={captchaRef} />

      <BolApiErrorMessage
        errorCode={errorCode}
        onClose={() => {
          setErrorCode(null)
          if (errorStatus === 404) {
            onSuccess(BolStep.IdForm, { identifier: '' })
          }
        }}
      />

      {success && (
        <AccessibleModal
          active={success}
          fixed
          onClose={() => {
            setSuccess(false)
            onSuccess(BolStep.IdForm)
          }}
        >
          <div className="lk-bol-new-user__ok">
            <div className="lk-bol-new-user__ok-icon" />
            <div>{t('widgetBolNewUser.recoverPinWithCcOk.title')}</div>
          </div>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('widgetBolNewUser.recoverPinWithCcOk.message'),
            }}
          />
        </AccessibleModal>
      )}
    </div>
  )
}

export default BolRecoverPinWithCc
