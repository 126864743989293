import { graphql, useStaticQuery } from 'gatsby'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import React, { useContext, useEffect, useState } from 'react'

import * as SanitySchema from '../../../../lib/types/sanity-schema'
import PromoCard from './PromoCard'

export const LKPromoFragment = graphql`
  fragment LKPromoFragment on SanityPromo {
    _id
    title
    scheduledStartDate
    scheduledEndDate
    body: _rawBody(resolveReferences: { maxDepth: 10 })
    relatedDocs {
      title
      pdf {
        _id
        slug {
          current
        }
      }
    }
  }
`

const promosQuery = graphql`
  query AllPromos {
    es: allSanityPromo(
      filter: { _id: { regex: "/^(?!drafts.).*/" }, language: { eq: "es" } }
      sort: { fields: scheduledEndDate, order: DESC }
    ) {
      edges {
        node {
          ...LKPromoFragment
        }
      }
    }
    eu: allSanityPromo(
      filter: { _id: { regex: "/^(?!drafts.).*/" }, language: { eq: "eu" } }
      sort: { fields: scheduledEndDate, order: DESC }
    ) {
      edges {
        node {
          ...LKPromoFragment
        }
      }
    }
    ca: allSanityPromo(
      filter: { _id: { regex: "/^(?!drafts.).*/" }, language: { eq: "ca" } }
      sort: { fields: scheduledEndDate, order: DESC }
    ) {
      edges {
        node {
          ...LKPromoFragment
        }
      }
    }
  }
`

const WidgetPromosList: React.FC = () => {
  const { language } = useContext(I18nextContext)
  const allPromos = useStaticQuery(promosQuery)
  const [promos, setPromos] = useState<SanitySchema.SanityKeyed<SanitySchema.Promo>[]>([])

  useEffect(() => {
    const items = allPromos && allPromos[language]?.edges?.map((item) => item.node)
    setPromos(items || [])
  }, [allPromos, language])

  return (
    <div data-testid="promosList">
      {promos.map((promo) => (
        <PromoCard promo={promo} key={promo._key || promo._id} />
      ))}
    </div>
  )
}
export default WidgetPromosList
