import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import HelpBox from '../common/HelpBox'

export interface FinishBusinessIdNotSupportedProps {
  language: string
}

const FinishBusinessIdNotSupported: FC<FinishBusinessIdNotSupportedProps> = ({ language }) => {
  const { t } = useTranslation()
  const helpLinks = {
    offices: { es: '/es/oficinas-cajeros/', eu: '/eu/bulegoak-kutxazainak/', ca: '/ca/oficines-caixes/' },
    form: {
      es: 'https://www.laboralkutxa.com/es/empresas/formulario-empresas/claves-banca-online/',
      eu: 'https://www.laboralkutxa.com/eu/enpresak/enpresak-formularioak/online-banka-gakoak/',
      ca: '#',
    },
  }
  return (
    <div className="lk-bol-new-user" data-testid="bol-recover-pass">
      <p>{t('widgetBolNewUser.finishBusinessIdNotSupported.intro')}</p>
      <div className="lk-bol-new-user__help-boxes">
        <HelpBox
          title={t('widgetBolNewUser.finishBusinessIdNotSupported.offices.title')}
          body={t('widgetBolNewUser.finishBusinessIdNotSupported.offices.text')}
          buttonText={t('widgetBolNewUser.finishBusinessIdNotSupported.offices.button')}
          href={`${helpLinks.offices[language]}?tab=offices`}
        />
        <HelpBox
          title={t('widgetBolNewUser.finishBusinessIdNotSupported.form.title')}
          body={t('widgetBolNewUser.finishBusinessIdNotSupported.form.text')}
          buttonText={t('widgetBolNewUser.finishBusinessIdNotSupported.form.button')}
          href={`${helpLinks.form[language]}`}
        />
      </div>
    </div>
  )
}

export default FinishBusinessIdNotSupported
