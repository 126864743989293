import React, { FC } from 'react'

import * as SanitySchema from '../../lib/types/sanity-schema'
import Banner from '../Banner/Banner'
import ContentFilterBuilder from '../ContentFilter/ContentFilter'
import SectionBuilder from '../Section/Section'

interface LkSanityPageComponentProps {
  component:
    | SanitySchema.SanityKeyed<SanitySchema.Section>
    | SanitySchema.SanityKeyed<SanitySchema.Banner>
    | SanitySchema.SanityKeyed<SanitySchema.ContentFilter>
}

const LkSanityPageComponent: FC<LkSanityPageComponentProps> = ({ component }) => {
  let el = null
  const { _key: key, _type: type } = component
  switch (type) {
    case `section`:
      // eslint-disable-next-line no-underscore-dangle
      el = SectionBuilder(component)
      break
    case 'banner':
      el = Banner(component)
      break
    case 'contentFilter':
      el = ContentFilterBuilder(component)
      break
    default:
      throw Error(`page component unknown: ${type}`)
      // eslint-disable-next-line no-underscore-dangle
      el = <div key={key}>🔴 Unknown element type. No match component for {component._type}</div>
  }
  return el
}

export default LkSanityPageComponent
