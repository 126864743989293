import './Video.scss'

import React, { FC } from 'react'
import ReactPlayer from 'react-player'

import * as SanitySchema from '../../lib/types/sanity-schema'
import LkPortableText from '../PortableText/PortableText'

export type VideoProps = Omit<SanitySchema.Video, '_type'>

const Video: FC<VideoProps> = ({ videoUrl, autoplay, title, showTitle, description }) => (
  <div className="lk-video" data-testid="video">
    {title && showTitle && <div className="lk-video__title">{title}</div>}
    <div className="lk-video__container">
      <ReactPlayer
        url={videoUrl.url}
        muted
        playing={autoplay}
        controls
        width="100%"
        height="100%"
        aria-hidden="true"
        loop
        className="react-player"
      />
    </div>
    {description && (
      <div className="lk-video__description">
        <LkPortableText blocks={description} />
      </div>
    )}
  </div>
)

export default Video
