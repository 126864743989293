import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import React, { useEffect, useState } from 'react'

import { getSanityClientLkConfig } from '../../../../lib/lkWebConfig'
import SanitySchema from '../../../../lib/types/sanity-schema'

export interface BannerImageProps {
  illustration: SanitySchema.Illustration
}

const BannerImage: React.FC<BannerImageProps> = ({ illustration }) => {
  const breakpoints = useBreakpoint()
  const [imageSrc, setImageSrc] = useState('')
  const [imageAlt, setImageAlt] = useState('')

  useEffect(() => {
    let bannerWidth = 1024
    if (breakpoints.md) {
      bannerWidth = 712
    }
    if (breakpoints.sm) {
      bannerWidth = 520
    }
    if (breakpoints.xs) {
      bannerWidth = 320
    }
    const { image } = illustration
    const imageData = getGatsbyImageData(image, { width: bannerWidth }, getSanityClientLkConfig())
    const { src } = imageData?.images?.fallback || { src: '' }
    setImageSrc(src)
    setImageAlt(image?.alt)
  }, [breakpoints, illustration])

  return <img className="lk-hero__image" src={imageSrc} alt={imageAlt} title={imageAlt} />
}

export default BannerImage
