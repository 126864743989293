import React, { useCallback, useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import LKApis from '../../../../../services/lk-apis/LKApisProvider'
import { PatchPinArgs, PatchPinOption } from '../../../../../services/lk-apis/types'
import AccessibleModal from '../../../../AccessibleModal/AccessibleModal'
import BolApiErrorMessage from '../../../common/BolApiErrorMessage'
import LkCaptcha, { LkCaptchaRef } from '../../../common/LkCaptcha'
import BaseForm, { FormFields } from '../common/BaseForm'
import { BolFormProps, BolStep } from '../common/types'

const formFields: FormFields[] = ['name', 'surname', 'email', 'cardNumber', 'cvv2', 'expirationDate']

export interface BolRecoverPassFormProps extends BolFormProps {
  petitionId: string
}

const BolRecoverPassForm: React.FC<BolRecoverPassFormProps> = ({ identifier, petitionId, onSuccess }) => {
  const captchaRef = useRef<LkCaptchaRef>()
  const { lkWebApi } = useContext(LKApis)
  const { t } = useTranslation()
  const [success, setSuccess] = useState(false)
  const [errorCode, setErrorCode] = useState<string>(null)
  const [errorStatus, setErrorStatus] = useState<number>(null)
  const [name, setName] = useState<string>(null)

  const handleSubmit = useCallback(
    async (data) => {
      const captchaToken = await captchaRef.current.getCaptchaToken()
      const apiData: PatchPinArgs = {
        petitionId,
        cardRegisterCustomerData: {
          name: data.name,
          surname: data.surname,
          email: data.email,
          cardNumber: data.cardNumber,
          expirationDate: data.expirationDate,
          cvv2: data.cvv2,
        },
      }
      try {
        const response = await lkWebApi.customerManagement.patchPin(
          PatchPinOption.signupWithCard,
          apiData,
          captchaToken,
        )
        if (response?.status === 200) {
          setName(data?.name)
          setSuccess(true)
        } else {
          setSuccess(false)
          setErrorCode('UNEXPECTED_STATUS_CODE')
        }
        setErrorStatus(response?.status || -1)
      } catch (error) {
        setErrorStatus(error?.response?.status || -1)
        setErrorCode(error?.response?.data?.errors?.detail || 'UNKNOWN_ERROR')
      }
    },
    [lkWebApi.customerManagement, petitionId],
  )

  return (
    <div data-testid="bol-form-register-with-cc">
      <p className="lk-bol-new-user__title">{t('widgetBolNewUser.registerWithCcIntro')}</p>
      <BaseForm
        identifier={identifier}
        onFormSubmit={handleSubmit}
        fields={formFields}
        submitLabel={t('widgetBolNewUser.registerWithCcButton')}
        showLegalCheckbox
      />
      <LkCaptcha ref={captchaRef} />

      <BolApiErrorMessage
        errorCode={errorCode}
        onClose={() => {
          setErrorCode(null)
          if (errorStatus === 404) {
            onSuccess(BolStep.IdForm, { identifier: '' })
          }
        }}
      />

      {success && (
        <AccessibleModal
          active={success}
          fixed
          size="medium"
          onClose={() => {
            setSuccess(false)
            onSuccess(BolStep.IdForm)
          }}
        >
          <div className="lk-bol-new-user__ok">
            <div className="lk-bol-new-user__ok-icon" />
            <div>
              <strong>{name}</strong> {t('widgetBolNewUser.registerWithCcOk.title')}
            </div>
          </div>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('widgetBolNewUser.registerWithCcOk.message'),
            }}
          />
        </AccessibleModal>
      )}
    </div>
  )
}

export default BolRecoverPassForm
