import React, { FC } from 'react'

import useLkUrlUtils from '../../../../hooks/useLkUrlUtils'
import usePagesUtils, { PageNodeContent } from '../../../../hooks/usePagesUtils'

export interface WidgetSiteplanProps {
  language: string
  path: string
}

interface TreeNode {
  page?: PageNodeContent
  children: TreeNode[]
  title?: string
}

const WidgetSiteplan: FC<WidgetSiteplanProps> = ({ language, path }) => {
  const { allTitles } = usePagesUtils()
  const { webAbsoluteUrl } = useLkUrlUtils()
  const filteredPages = allTitles.pageTitles.edges.filter(
    (page) => page.node.language === language && !page.node.hideInSearch,
  )

  const buildTree = (pages): TreeNode => {
    const tree = {} as TreeNode

    pages.forEach((page: PageNodeContent) => {
      const slugWithoutTrailingSlashes = path?.slice(1, path.length - 1)
      const skipCurrentPage = page.node.slug.current === slugWithoutTrailingSlashes
      if (skipCurrentPage) {
        return
      }

      let pageSlugParts = page.node.slug.current.split('/')
      if (pageSlugParts.length > 1) {
        pageSlugParts = pageSlugParts.slice(1) // Erase the language part of the url
      }

      let currentNode = tree

      pageSlugParts.forEach((_, index) => {
        const result = pageSlugParts.slice(0, index + 1).join('/')
        if (!currentNode[result]) {
          currentNode[result] = {
            page: null,
            children: {},
            title: null,
          }
        }

        const isLastPartOfSlug = index === pageSlugParts.length - 1
        if (isLastPartOfSlug) {
          currentNode[result].page = page
          currentNode[result].title = page.node.openGraph.title
        }

        currentNode = currentNode[result].children
      })
    })

    return tree
  }

  const cleanTreeNode = (initialNode: TreeNode) => {
    const currentNode = initialNode
    if (Object.keys(currentNode.children).length > 0) {
      Object.entries(currentNode.children).forEach(([key, child]) => {
        cleanTreeNode(child)
      })
      const isUnexistingParent = currentNode.page === null && Object.keys(currentNode.children).length > 0
      if (isUnexistingParent) {
        if (Object.keys(currentNode.children).length === 1) {
          const childKey = Object.keys(currentNode.children)[0]
          const childNode = currentNode.children[childKey]
          currentNode.page = childNode.page
          currentNode.title = childNode.title
          currentNode.children = childNode.children
        } else {
          return Object.values(currentNode.children)
        }
      }
    }
    return currentNode
  }

  const pageTree = buildTree(filteredPages)

  const cleanedTree = Object.entries(pageTree).reduce((acc, [key, value]) => {
    const result = cleanTreeNode(value)
    const resultKeys = Object.keys(result)
    if (!resultKeys.includes('page') && !resultKeys.includes('children') && !resultKeys.includes('title')) {
      acc = { ...acc, ...result }
    } else {
      acc[key] = result
    }
    return acc
  }, {} as TreeNode)

  const renderTree = (node: TreeNode) => (
    <ul>
      {Object.entries(node).map(([key, treeNode]) => {
        if (treeNode?.page?.node) {
          return (
            <li key={key}>
              <a href={webAbsoluteUrl(treeNode.page.node.slug.current)}>{treeNode.page.node.openGraph.title}</a>
              {Object.keys(treeNode.children).length > 0 && renderTree(treeNode.children)}
            </li>
          )
        }
        return Object.keys(treeNode.children).length > 0 && renderTree(treeNode.children)
      })}
    </ul>
  )

  return <div>{renderTree(cleanedTree)}</div>
}

export default WidgetSiteplan
