import './TextWithIllustration.scss'

import React, { FC } from 'react'

import * as SanitySchema from '../../lib/types/sanity-schema'
import Figure from '../Figure/Figure'
import PortableText from '../PortableText/PortableText'

export type TextWithIllustrationProps = Omit<SanitySchema.TextWithIllustration, '_type'>

const TextWithIllustration: FC<TextWithIllustrationProps> = ({ text, illustration, layout, link }) => {
  const textElement = text && (
    <div className="lk-text">
      <PortableText blocks={text} />
    </div>
  )
  const imageElement = illustration && <Figure node={illustration.image as SanitySchema.MainImage} />
  return (
    <div className={`lk-text-and-image ${layout || ''}`} data-testid="text-with-illustration">
      {link && (
        <>
          {textElement}
          <a href={link}>{imageElement}</a>
        </>
      )}
      {!link && (
        <>
          {textElement}
          {imageElement}
        </>
      )}
    </div>
  )
}

export default TextWithIllustration
export const TextWithIllustrationBuilder: FC<TextWithIllustrationProps> = ({ text, illustration, layout, link }) => (
  <TextWithIllustration text={text} illustration={illustration} layout={layout} link={link} />
)
