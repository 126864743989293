import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as SanitySchema from '../../lib/types/sanity-schema'
import { calculateProgress } from './DecisionTreeUtils'

export interface DecisionTreeProgressProps {
  currentKey: string
  nodes: Array<SanitySchema.SanityKeyed<SanitySchema.DecisionTreeBranch>>
}

const DecisionTreeProgress: FC<DecisionTreeProgressProps> = ({ currentKey, nodes }) => {
  const { t } = useTranslation()
  const [currentProgress, setCurrentProgress] = useState<number>(0)
  const [max, setMax] = useState(0)
  const [now, setNow] = useState(0)
  useEffect(() => {
    const { actual, total } = calculateProgress(currentKey, nodes)
    setCurrentProgress((actual / total) * 100)
    setMax(total)
    setNow(actual)
  }, [currentKey, nodes])
  const id = `progressbar-${currentKey}`

  return (
    <div
      className="decisionTree-header__progress text-small"
      role="progressbar"
      aria-valuenow={now}
      aria-valuemax={max}
      aria-label={t('decisionTree.progress')}
    >
      <label htmlFor={id}>{t('decisionTree.progress')}</label>
      <div id={id} className="decisionTree-progressbar">
        <div className="decisionTree-progressbar__active" style={{ width: `${currentProgress}%` }} />
      </div>
    </div>
  )
}

export default DecisionTreeProgress
