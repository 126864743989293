import React, { FC } from 'react'

import { TravelOffer } from '../../../../../services/lk-apis/types'
import TravelInsuranceOfferBox from './TravelInsuranceChoiceBox'

export interface TravelInsuranceChoicesProps {
  offers: TravelOffer[]
  onChoiceSelected: (choice: TravelOffer) => void
}

const TravelInsuranceChoices: FC<TravelInsuranceChoicesProps> = ({ offers, onChoiceSelected }) => (
  <div className="lk-widget-travel__choices">
    {offers?.map((choice) => (
      <TravelInsuranceOfferBox offer={choice} key={choice.id} showButton onChoiceSelected={onChoiceSelected} />
    ))}
  </div>
)

export default TravelInsuranceChoices
