import './LoadingIndicator.scss'

import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

export interface LoadingIndicatorProps {
  loading: boolean
}

const LoadingIndicator: FC<LoadingIndicatorProps> = ({ loading }) => {
  const { t } = useTranslation()
  return (
    (loading && (
      <div className="loading-indicator" role="progressbar" aria-label={t('loadingIndicator.loading')}>
        <div className="loading-indicator__progress" />
      </div>
    )) ||
    null
  )
}
export default LoadingIndicator
