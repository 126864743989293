import React, { forwardRef, useImperativeHandle } from 'react'
import ReCAPTCHA from 'react-google-recaptcha-enterprise'
import { useTranslation } from 'react-i18next'

import { getReCaptchaKeyLkConfig } from '../../../lib/lkWebConfig'
import useGetCaptchaToken from '../widgets/WidgetCustomForm/useGetCaptchaToken'

export interface LkCaptchaRef {
  getCaptchaToken: () => Promise<string>
}

const LkCaptcha = (_props, ref) => {
  const { t } = useTranslation()
  const siteKey = getReCaptchaKeyLkConfig()
  const captchaRef = React.useRef<ReCAPTCHA>()
  const getCaptchaToken = useGetCaptchaToken()

  useImperativeHandle(ref, () => ({
    elementRef: captchaRef,
    getCaptchaToken: () => getCaptchaToken(captchaRef),
  }))

  return (
    <div className="captcha mt-16">
      <ReCAPTCHA ref={captchaRef} sitekey={siteKey} size="invisible" />
      <p
        className="text-xsmall"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t('customForm.captchaTerms'),
        }}
      />
    </div>
  )
}

export default forwardRef(LkCaptcha)
