import React, { FC, useCallback, useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import LKApis from '../../../../../services/lk-apis/LKApisProvider'
import BolApiErrorMessage from '../../../common/BolApiErrorMessage'
import LkCaptcha, { LkCaptchaRef } from '../../../common/LkCaptcha'
import WidgetBolBaseForm from '../common/BaseForm'

const FinishBetween14And17: FC = () => {
  const { t } = useTranslation()
  const { lkWebApi } = useContext(LKApis)
  const [emailSent, setEmailSent] = useState(false)
  const captchaRef = useRef<LkCaptchaRef>()
  const [errorCode, setErrorCode] = useState<string>(null)

  const handleSubmit = useCallback(
    async (data) => {
      try {
        const captcha = await captchaRef.current.getCaptchaToken()
        const response = await lkWebApi.customerManagement.postInformativeMail(
          { email: data.email, emailId: 'RecoveryKeyInfo' },
          captcha,
        )
        if (response?.status === 200) {
          setEmailSent(true)
        }
      } catch (error) {
        setErrorCode(error?.response?.data?.errors?.detail || 'UNKNOWN_ERROR')
      }
    },
    [lkWebApi],
  )

  return (
    <div className="lk-bol-new-user" data-testid="bol-between-14-and-17">
      {!emailSent && (
        <div>
          <p>{t('widgetBolNewUser.thanksForYourInterest')}</p>
          <p>{t('widgetBolNewUser.finishBetween14and17')}</p>
          <div className="lk-bol-new-user__help">
            <p>{t('widgetBolNewUser.finishBetween14and17MoreInfo')}</p>
            <WidgetBolBaseForm
              fields={['email']}
              onFormSubmit={handleSubmit}
              submitLabel={t('widgetBolNewUser.finishBetween14and17MoreInfoButton')}
              showLegalCheckbox
            />
            <LkCaptcha ref={captchaRef} />
          </div>
        </div>
      )}
      {emailSent && (
        <div className="lk-bol-new-user__help">
          <p>{t('widgetBolNewUser.finishBetween14and17MoreInfoSentOk')}</p>
        </div>
      )}
      <BolApiErrorMessage errorCode={errorCode} onClose={() => setErrorCode(null)} />
    </div>
  )
}

export default FinishBetween14And17
