import { Checkbox, Input, Radio, Textarea } from '@lk/lk-design-system'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import SanitySchema, { SanityKeyed } from '../../../../lib/types/sanity-schema'
import { DocWithSlug } from '../../../CTALink/CTALink'
import LegalCheckbox from '../../../LegalCheckbox/LegalCheckbox'

type CustomFormFieldType = SanityKeyed<SanitySchema.FormField> & {
  onInput: (e: unknown) => void
  onLegalChange: (fieldName: string, legalAccepted: boolean) => void
  error: boolean
  errorMessage: string
  legalTitle: string
  legalLink: DocWithSlug
}

const CustomFormField: FC<CustomFormFieldType> = ({
  fieldName,
  type,
  required,
  placeholder,
  label,
  onInput,
  isKeyCode,
  legalTitle,
  legalLink,
  onLegalChange,
  value,
  autoComplete,
  ...props
}) => {
  const { t } = useTranslation()
  let options
  const formId = props?.id || undefined
  switch (type) {
    case 'radio':
      options = props.fieldOptions.map((option, idx) => {
        const radioProps = { ...props, id: `${props.id}_${idx}` }
        return (
          <div key={option._key}>
            <Radio
              name={fieldName}
              label={option.label}
              value={option.value}
              onChange={onInput}
              required={required}
              checked={option.value === value}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...radioProps}
            />
          </div>
        )
      })
      return (
        <div className="lk-input__label lk-label lk-input__label">
          {label}
          {options}
        </div>
      )
    case 'checkbox':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Checkbox name={fieldName} label={label} {...props} />
    case 'legal':
      return (
        <LegalCheckbox
          fieldName={fieldName}
          legalTitle={legalTitle}
          legalLink={`/${legalLink?.slug?.current}`}
          onLegalChange={onLegalChange}
        />
      )
    case 'tel':
      return (
        <Input
          type={type}
          name={fieldName}
          required={required}
          placeholder={placeholder}
          label={label}
          onInput={onInput}
          id={formId}
          key={`input-${props?.key}`}
          pattern="(\+34|0034|34)?[0-9]{9}"
          title={t('customForm.titles.tel')}
          autoComplete={autoComplete}
        />
      )
    case 'dni':
      return (
        <Input
          type={type}
          name={fieldName}
          required={required}
          placeholder={placeholder}
          label={label}
          onInput={onInput}
          id={formId}
          key={`input-${props?.key}`}
          pattern="(([X-Zx-z]{1})([-]?)(\d{7})([-]?)([A-Za-z]{1}))|((\d{8})([-]?)([A-Za-z]{1}))"
          title={t('customForm.titles.dni')}
        />
      )
    case 'textArea':
      return (
        <Textarea
          name={fieldName}
          required={required}
          placeholder={placeholder}
          label={label}
          onInput={onInput}
          id={formId}
          key={`input-${props?.key}`}
          autoComplete={autoComplete}
        />
      )
    default:
      return (
        <Input
          type={type}
          name={fieldName}
          required={required}
          placeholder={placeholder}
          label={label}
          onInput={onInput}
          id={formId}
          key={`input-${props?.key}`}
          autoComplete={autoComplete}
        />
      )
  }
}
export default CustomFormField
